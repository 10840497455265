import { SerializedError } from '@reduxjs/toolkit';

export interface ServerValidationError {
    statusCode: number;
    statusText: string;
    errors: {
            field: string;
            message: string;
        }[];
}

export function isServerValidationError(error: any): error is ServerValidationError {
  if (error.errors && error.statusCode && error.statusText) {
    return true;
  }

  return false;
}

export interface CustomersState {
    loading: boolean
    error: ServerValidationError | SerializedError | null
    isSended: boolean
    customer: Customer | null
    customers: Customer[] | null
    building: Building | null
    buildings: Building[] | null
    searchResults: Customer[] | null
    searchBuildingResults: Building[] | null
    serviceRegistryState:'pending' | 'fullfilled' | 'rejected'
    serviceBuildState:'pending' | 'fullfilled' | 'rejected'
    printRegistry: number | null
    printBuilding: number | null
}

export interface searchBuildingResults {
    customerId: number
    address: string
}

export interface Customer {
    id?: number
    firstName: string
    lastName: string
    birthCity: string
    birthDate: string
    address: string
    houseNumber: string
    city : string
    tel: string
    email: string
    fiscalCode: string
    postalCode: string
    province: string
}

export interface Building {
    id?: number
    customerId?: number
    customer:Customer
    sheet: number
    particle: number
    city: string
    address: string
    area: number
    buildingPractice: string
    propertyDeed: PropertyDeed
    buildingType: BuildingType
    postalCode: string
    province: string
    yearOfConstruction: string
    subordinate: number
    paymentDate: string
    buildingPracticeDate: string
    unitOfMeasurement: UnitOfMeasurement
}

export enum UnitOfMeasurement {
    METER = 'meter'
}

export enum PropertyDeed {
    PROPRIETARIO_COMPROPRIETARIO = 'property-0',
    DETENTORE_CODETENTORE = 'property-1',
    FAMILIARE_CONVIVENTE = 'property-2',
    CONDOMINIO = 'property-3',
}

export enum BuildingType {
    EDIFICIO_IN_LINEA = 'building-0',
    EDIFICIO_A_SCHIERA = 'building-1',
    COSTRUZIONE_ISOLATA = 'building-2',
    ALTRO = 'building-3',
}

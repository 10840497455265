/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Order, selectOrders, selectSelectedOrder } from '..';
import {
  actions as customerActions,
  Building,
  Customer,
  selectBuilding,
  selectCustomer,
  selectSearchBuildingResults,
  selectSearchResults,
  UnitOfMeasurement,
} from '../../Customers';
import { ReactComponent as Search } from '../../../assets/Icon-search.svg';
import { ReactComponent as Printer } from '../../../assets/Icon-printer.svg';
import { ReactComponent as Close } from '../../../assets/Icon-close-white.svg';
import Registry from '../../Customers/components/Registry';
import Build from '../../Customers/components/Build';
import {
  createBuilding,
  createRegistry,
  searchRegistry,
  searchBuilding,
  printRegistry,
  printBuilding,
} from '../../Customers/services';
import { printOffer } from '../services';

export type OrderManagementProps = {
    orders?: Order[]
}

const OrderManagement: FunctionComponent<OrderManagementProps> = () => {
  const selectedOrder = useSelector(selectSelectedOrder);
  const selectedBuilding = useSelector(selectBuilding);
  const selectedSearchResults = useSelector(selectSearchResults);
  const selectedSearchBuildingResults = useSelector(selectSearchBuildingResults);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [registryInput, setRegistryInput] = useState<string>();
  const [buildingInput, setBuildingInput] = useState<string>();
  const dispatch = useDispatch();
  const selectSelectedCustomer = useSelector(selectCustomer);

  const registrySubmitHandler = useCallback(
    (customer: Customer) => {
      dispatch(customerActions.setCustomer(customer));
      dispatch(createRegistry(customer));
    },
    [dispatch],
  );

  const buildingSubmitHandler = useCallback(
    (values: Building) => {
      if (selectSelectedCustomer) {
        dispatch(
          customerActions.setBuilding({
            ...values,
            unitOfMeasurement: UnitOfMeasurement.METER,
            customerId: selectSelectedCustomer?.id,
          }),
        );
        dispatch(
          createBuilding({
            ...values,
            unitOfMeasurement: UnitOfMeasurement.METER,
            customerId: selectSelectedCustomer.id,
          }),
        );
      }
    },
    [dispatch, selectSelectedCustomer],
  );

  /*   const insertRegistry = () => {
    if (selectedCustomer?.id) {
      return true;
    }
    return false;
  }; */

  /*   useEffect(() => {
    insertRegistry();
  }, [selectedCustomer]); */

  useEffect(() => {
    if (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate' && selectedOrder?.status !== 'rejected') {
      setSelectedCustomer(selectedOrder?.building.customer);
    }
  }, [selectedOrder]);
  useEffect(() => {
    if ((selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate') || selectSelectedCustomer) {
      if (!selectSelectedCustomer?.lastName) {
        setRegistryInput('');
      } else {
        setRegistryInput(`${selectSelectedCustomer?.firstName} ${selectSelectedCustomer?.lastName}`);
      }
    } else {
      setRegistryInput('');
    }
  }, [selectSelectedCustomer, selectedOrder]);

  useEffect(() => {
    if (selectedBuilding) {
      if ((selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate') || selectedBuilding.customerId) {
        setBuildingInput(`${selectedBuilding.address}`);
      } else {
        setBuildingInput('');
      }
    } else {
      setBuildingInput('');
    }
  }, [selectedBuilding, selectedOrder]);

  return (
    <div className="max-w-md mt-8 mb-8 shadow-md">
      <h1 className="text-center text-white py-4 text-xl bg-primary">
        Gestione ordine
        <div>{selectedOrder?.reference}</div>
      </h1>
      <Registry onSubmit={registrySubmitHandler} customer={selectSelectedCustomer || undefined} />
      <div className="relative">
        <input
          type="text"
          value={registryInput}
          placeholder="Cerca anagrafica"
          className={selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate' ? 'w-full pr-16 input input-primary input-bordered rounded-none' : 'text-white font-semibold w-full pr-16 input input-primary input-bordered rounded-none'}
          onChange={(e) => {
            setRegistryInput(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              dispatch(searchRegistry(e.target.value));
            }
          }}
          disabled={
                        selectedOrder?.status !== 'acquisition'
                        && selectedOrder?.status !== 'estimate'
                    }
        />
        <div className="w-full shadow-md absolute bg-white top-12 z-10 text-primary">
          {registryInput && registryInput.length > 2 && selectedSearchResults
            ?.filter((value, i) => i < 5)
            .map((e) => (
              <div
                key={e.id}
                className="flex justify-between p-2 hover:bg-secondary cursor-pointer"
                onClick={() => {
                  dispatch(customerActions.setCustomer(e));
                  dispatch(customerActions.setResetSearchResult());
                }}
              >
                <div>
                  {e.firstName}
                  {' '}
                  {e.lastName}
                  {' '}
                </div>
                <div>{moment(e.birthDate).format('DD/MM/YY')}</div>
              </div>
            ))}
        </div>
        <button
          type="button"
          className="absolute top-0 right-0 rounded-none btn btn-primary"
          onClick={() => {
            dispatch(customerActions.setResetCustomer());
            dispatch(customerActions.setResetSearchResult());
            dispatch(customerActions.setResetBuilding());
            dispatch(customerActions.setResetSearchBuildingResult());
          }}
          disabled={
                        selectedOrder?.status !== 'acquisition'
                        && selectedOrder?.status !== 'estimate'
                    }
        >
          {selectSelectedCustomer
            ? (
              <Close />
            )
            : <Search /> }
        </button>
      </div>
      <Build onSubmit={buildingSubmitHandler} building={selectedBuilding || undefined} />
      <div className="relative">
        <input
          value={buildingInput}
          type="text"
          placeholder="Cerca immobile"
          className={selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate' ? 'w-full pr-16 input input-primary input-bordered rounded-none' : 'text-white font-semibold w-full pr-16 input input-primary input-bordered rounded-none'}
          disabled={selectedOrder?.status !== 'acquisition'
          && selectedOrder?.status !== 'estimate' || !selectSelectedCustomer}
          onChange={(e) => {
            setBuildingInput(e.target.value);
            if (e.target.value && e.target.value.length > 2) {
              selectSelectedCustomer?.id
                ? dispatch(
                  searchBuilding({
                    address: e.target.value,
                    customerId: selectSelectedCustomer?.id,
                  }),
                )
                : null;
            }
          }}
        />
        <div className="w-full shadow-md absolute bg-white top-12 z-10 text-primary">
          {buildingInput && buildingInput.length > 2 && selectedSearchBuildingResults
            ?.filter((value, i) => i < 5)
            .map((e) => (
              <div
                key={e.id}
                className="flex justify-between p-2 hover:bg-secondary cursor-pointer"
                onClick={() => {
                  dispatch(customerActions.setBuilding(e));
                  dispatch(customerActions.setResetSearchBuildingResult());
                }}
              >
                <div>{e.address}</div>
              </div>
            ))}
        </div>
        <button
          type="button"
          className="absolute top-0 right-0 rounded-none btn btn-primary"
          disabled={selectedOrder?.status !== 'acquisition'
          && selectedOrder?.status !== 'estimate'}
          onClick={() => {
            dispatch(customerActions.setResetBuilding());
            dispatch(customerActions.setResetSearchBuildingResult());
          }}
        >
          {selectedBuilding
            ? (
              <Close />
            )
            : <Search /> }
        </button>
      </div>
      <div
        onClick={() => selectSelectedCustomer?.id
          && dispatch(printRegistry(selectSelectedCustomer.id))}
        className={selectedOrder !== null && selectSelectedCustomer ? 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center' : 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center btn-disabled'}
      >
        <h1 className="mr-4">Stampa anagrafica</h1>
        <Printer />
      </div>
      <div
        className={selectedOrder !== null && selectedBuilding ? 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center border-t border-primary' : 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center border-t border-primary btn-disabled'}
        onClick={() => selectedBuilding?.id && dispatch(printBuilding(selectedBuilding.id))}
      >
        <h1 className="mr-4">Stampa immobile</h1>
        <Printer />
      </div>
      <div
        className={selectedOrder !== null && selectedOrder?.status !== 'rejected' ? 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center border-t border-primary' : 'p-4 cursor-pointer hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center border-t border-primary btn-disabled'}
        onClick={() => selectedOrder?.id && dispatch(printOffer(selectedOrder.id))}
      >
        <h1 className="mr-4">Stampa offerta</h1>
        <Printer />
      </div>
    </div>
  );
};

export default OrderManagement;

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Navigate } from 'react-router';
import { LoginFormData } from './components/LoginForm';

export const login = createAsyncThunk('auth/login', async (values: LoginFormData) => {
  const response = await axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login`, values, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res);
  return response;
});

export const logout = createAsyncThunk('auth/logout', async () => {
  const response = await axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/logout`)
    .then((res) => res);
  return response;
});

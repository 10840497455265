import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommonState } from '.';
import { RootState } from '../../store';

const initialState: CommonState = {
  ableToSend: false,
  isDifferentOrder: false,
  isLoading: false,
};

export const selectIsLoading = createSelector(
  (state: RootState) => state.common,
  (state: CommonState) => state.isLoading,
);

export const selectIsAbleToSend = createSelector(
  (state: RootState) => state.common.ableToSend,
  (isAbleToSend) => isAbleToSend,
);

export const selectIsDifferentOrder = createSelector(
  (state: RootState) => state.common.isDifferentOrder,
  (isDifferentOrder) => isDifferentOrder,
);

const { actions, name, reducer } = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setAbleToSend: (state: CommonState, action: PayloadAction<boolean>) => {
      state.ableToSend = action.payload;
    },
    setIsDifferentOrder: (state: CommonState, action: PayloadAction<boolean>) => {
      state.isDifferentOrder = action.payload;
    },
  },
});

export { reducer, actions, name };

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { Axios, AxiosError } from 'axios';
import {
  Building, Customer, CustomersState, ServerValidationError,
} from '.';
import { RootState } from '../../store';
import {
  createBuilding, createRegistry, searchBuilding, searchRegistry, printRegistry, printBuilding,
} from './services';

const initialState: CustomersState = {
  loading: false,
  error: null,
  isSended: false,
  customer: null,
  customers: null,
  building: null,
  buildings: null,
  searchResults: null,
  searchBuildingResults: null,
  serviceRegistryState: 'pending',
  serviceBuildState: 'pending',
  printRegistry: null,
  printBuilding: null,
};

export const selectIsLoading = createSelector(
  (state: RootState) => state.customers.loading,
  (loading) => loading,
);

export const selectCustomer = createSelector(
  (state: RootState) => state.customers.customer,
  (customer) => customer,
);

export const selectBuilding = createSelector(
  (state: RootState) => state.customers.building,
  (building) => building,
);

export const selectError = createSelector(
  (state: RootState) => state.customers.error,
  (error) => error,
);

export const selectIsError = createSelector(
  (state: RootState) => state.customers.error,
  (error) => axios.isAxiosError(error),
);

export const selectSearchResults = createSelector(
  (state: RootState) => state.customers.searchResults,
  (searchResults) => searchResults,
);

export const selectSearchBuildingResults = createSelector(
  (state: RootState) => state.customers.searchBuildingResults,
  (searchBuildingResults) => searchBuildingResults,
);

export const selectStateRegistryService = createSelector(
  (state: RootState) => state.customers.serviceRegistryState,
  (serviceRegistryState) => serviceRegistryState,
);

export const selectStateBuildService = createSelector(
  (state: RootState) => state.customers.serviceBuildState,
  (serviceBuildState) => serviceBuildState,
);

const { actions, name, reducer } = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomer: (state: CustomersState, action: PayloadAction<Customer | null>) => {
      state.customer = action.payload;
    },
    setResetCustomer: (state: CustomersState) => {
      state.customer = null;
    },
    setBuilding: (state: CustomersState, action: PayloadAction<Building | null>) => {
      state.building = action.payload;
    },
    setResetBuilding: (state: CustomersState) => {
      state.building = null;
    },
    setResetSearchResult: (state: CustomersState) => {
      state.searchResults = [];
    },
    setResetSearchBuildingResult: (state: CustomersState) => {
      state.searchBuildingResults = [];
    },
    setError: (state: CustomersState) => {
      state.error = null;
    },
    setStateRegistryService: (state: CustomersState) => {
      state.serviceRegistryState = 'pending';
    },
    setStateBuildService: (state: CustomersState) => {
      state.serviceBuildState = 'pending';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createRegistry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createRegistry.fulfilled, (state, action) => {
      if (action.payload) {
        if (state.customer) {
          state.serviceRegistryState = 'fullfilled';
          state.customer.id = action.payload.data.id;
        }
        state.loading = false;
      } else {
        state.customer = null;
        state.loading = false;
      }
    });
    builder.addCase(createRegistry.rejected, (state, action) => {
      if (action.error && action.error.message) {
        state.serviceRegistryState = 'rejected';
        state.error = JSON.parse(action.error.message);
      }

      state.loading = false;
    });
    builder.addCase(createBuilding.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createBuilding.fulfilled, (state, { payload }) => {
      if (payload) {
        if (state.building) {
          state.serviceBuildState = 'fullfilled';
          state.building.id = payload.data.id;
        }
        state.loading = false;
      } else {
        state.building = null;
        state.loading = false;
      }
    });
    builder.addCase(createBuilding.rejected, (state, action) => {
      if (action.error && action.error.message) {
        state.serviceBuildState = 'rejected';
        state.error = JSON.parse(action.error.message);
      }
      state.loading = false;
    });
    builder.addCase(searchRegistry.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(searchRegistry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.searchResults = payload.data;
    });
    builder.addCase(searchRegistry.rejected, (state, action) => {
      if (action.error && action.error.message) {
        state.error = JSON.parse(action.error.message);
      }

      state.loading = false;
    });
    builder.addCase(searchBuilding.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(searchBuilding.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.searchBuildingResults = payload.data;
    });
    builder.addCase(searchBuilding.rejected, (state, action) => {
      if (action.error && action.error.message) {
        state.error = JSON.parse(action.error.message);
      }

      state.loading = false;
    });
    builder.addCase(printRegistry.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(printRegistry.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.printRegistry = payload.data;
    });
    builder.addCase(printRegistry.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
    builder.addCase(printBuilding.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(printBuilding.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.printBuilding = payload.data;
    });
    builder.addCase(printBuilding.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
  },
});

export { reducer, actions, name };

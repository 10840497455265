/* eslint-disable max-len */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import DatePicker from 'react-date-picker';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../../assets/Icon-close.svg';
import { ReactComponent as PenBig } from '../../../assets/Icon-penBig.svg';
import { ReactComponent as Eye } from '../../../assets/Icon-eye.svg';
import '@themesberg/flowbite';
import {
  Building, BuildingType, PropertyDeed, selectBuilding, selectCustomer, selectError, selectStateBuildService,
  actions as customersActions,
} from '..';
import { selectSelectedOrder } from '../../Orders';

export interface BuildFormProps {
    building: Building | undefined
    onSubmit: (values: Building) => void
}

const today = new Date();
today.setHours(0, 0, 0, 0);

const schema = yup.object().shape({
  sheet: yup.number().positive('Deve essere maggiore di 0').transform((currentValue, originalValue) => {
    const value = originalValue === '' ? null : currentValue;
    return value;
  }).nullable()
    .required('Foglio richiesto'),
  particle: yup.number().positive('Deve essere maggiore di 0').transform((currentValue, originalValue) => {
    const value = originalValue === '' ? null : currentValue;
    return value;
  }).nullable()
    .required('Particella richiesta'),
  city: yup.string().required('Comune richiesto'),
  address: yup.string().required('Indirizzo richiesto'),
  area: yup.number().positive('Deve essere maggiore di 0').transform((currentValue, originalValue) => {
    const value = originalValue === '' ? null : currentValue;
    return value;
  }).nullable()
    .required('Superficie richiesta'),
  buildingPractice: yup.string().required('Numero pratica richiesto'),
  propertyDeed: yup.string().oneOf(Object.values(PropertyDeed)).required('Effettuare una scelta'),
  buildingType: yup.string().oneOf(Object.values(BuildingType)).required('Effettuare una scelta'),
  postalCode: yup.string().length(5, 'Inserire 5 caratteri').required('Codice postale richiesto'),
  province: yup.string().length(2, 'Inserire 2 caratteri').required('Provincia richiesta'),
  yearOfConstruction: yup.number().min(1900, 'Inserire anno corretto').max(2100, 'Inserire anno corretto').transform((currentValue, originalValue) => {
    const value = originalValue === '' ? null : currentValue;
    return value;
  })
    .nullable()
    .optional(),
  subordinate: yup.number().optional(),
  /* paymentDate: yup.date().required('Data bonifico richiesta'), */
  buildingPracticeDate: yup.date().max(today, 'La data deve essere inferiore ad oggi').required('Data pratica richiesta'),
});

const Build: FunctionComponent<BuildFormProps> = ({
  building,
  onSubmit = () => {},
}) => {
  const {
    register, control, handleSubmit, setValue, formState: { errors },
  } = useForm<Building>({
    // defaultValues: {
    //   sheet: undefined,
    // },
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const selectedCustomer = useSelector(selectCustomer);
  const selectedBuilding = useSelector(selectBuilding);
  const selectedOrder = useSelector(selectSelectedOrder);
  const selectedError = useSelector(selectError);
  const serviceStateBuild = useSelector(selectStateBuildService);

  const onSubmitHandler = useCallback((values: Building) => {
    onSubmit(values);
  }, [onSubmit]);

  useEffect(() => {
    if (serviceStateBuild === 'rejected') {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [serviceStateBuild]);

  const onCloseModal = () => {
    if (selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate') {
      dispatch(customersActions.setError());
    }
    setShowModal(false);
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className={selectedCustomer ? 'p-4 cursor-pointer w-full hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center' : 'p-4 cursor-pointer w-full hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center btn-disabled'}
      >
        <h1 className="mr-4">{selectedBuilding || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate') ? 'Visualizza dati immobile' : 'Compila dati immobile'}</h1>
        {selectedBuilding || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate')
          ? <Eye />
          : <PenBig />}
      </button>
      {showModal ? (
        <div>
          <div className="justify-center items-center flex overflow-y-auto fixed inset-0 z-50 w-full mx-auto my-0">
            <div className="relative my-6 h-screen lg:w-auto w-full">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">IMMOBILE CLIENTE</h3>
                  <button
                    type="button"
                    className=""
                    onClick={onCloseModal}
                  >
                    <Close className="text-right" />
                  </button>
                </div>
                {/* body */}
                {((building && serviceStateBuild !== 'rejected') || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate'))
                && (
                <form>
                  <div className="relative overflow-y-auto">
                    <div className="flex flex-col">
                      <div className="flex flex-col items-start justify-between">
                        <div className="bg-gray-200 w-full">
                          <div className="flex items-center p-6 justify-between flex-col sm:flex-row">
                            <h1 className="text-xl font-semibold sm:mr-8 w-full mr-0 sm:mb-0 mb-4">
                              Dati immobile:
                            </h1>
                            <label
                              className="relative w-full sm:mr-8 mr-0 sm:my-4 my-8 tooltip tooltip-primary"
                              data-tip="Foglio"
                              htmlFor="sheet"
                            >
                              <input
                                value={building?.sheet}
                                id="sheet"
                                type="number"
                                placeholder="Foglio"
                                className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                disabled
                              />
                            </label>
                            <label className="relative w-full sm:mr-8 mr-0 sm:my-0 my-4 mb-12 tooltip tooltip-primary" data-tip="Particella" htmlFor="particle">
                              <input
                                value={building?.particle}
                                id="particle"
                                type="number"
                                placeholder="Particella"
                                className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                disabled
                              />
                            </label>
                            <label htmlFor="subordinate" className="tooltip tooltip-primary w-full" data-tip="Subalterno">
                              <input
                                value={building?.subordinate}
                                id="subordinate"
                                type="number"
                                placeholder="Subalterno"
                                className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                disabled
                              />
                            </label>
                          </div>
                          <div className="flex sm:items-center p-6 justify-end sm:flex-row flex-col">
                            <label className="relative w-full sm:w-72 sm:mr-8 mr-0 sm:mb-0 mb-4 tooltip tooltip-primary" htmlFor="city" data-tip="Comune">
                              <input
                                value={building?.city}
                                id="city"
                                type="text"
                                placeholder="Comune"
                                className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                disabled
                              />
                            </label>
                            <div className="flex my-8 sm:my-0">
                              <label className="relative w-24 mr-8 tooltip tooltip-primary" htmlFor="postalCode" data-tip="CAP">
                                <input
                                  value={building?.postalCode}
                                  id="postalCode"
                                  type="text"
                                  placeholder="CAP"
                                  className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                  disabled
                                />
                              </label>
                              <label className="relative w-24 tooltip tooltip-primary" htmlFor="province" data-tip="Provincia">
                                <input
                                  value={building?.province}
                                  id="province"
                                  type="text"
                                  placeholder="Prov."
                                  className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row sm:items-start p-6 justify-end">
                            <div className="flex flex-row md:flex-row sm:flex-col flex-wrap">
                              <label className="relative w-64 sm:mr-8 mr-4 mb-12 tooltip tooltip-primary" htmlFor="address" data-tip="Indirizzo">
                                <input
                                  value={building?.address}
                                  id="address"
                                  type="text"
                                  placeholder="Indirizzo"
                                  className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                  disabled
                                />
                              </label>
                            </div>
                            <div className="flex flex-row md:flex-row sm:flex-col flex-wrap">
                              <label className="w-38 sm:mr-8 mr-4 mb-12 relative tooltip tooltip-primary" htmlFor="year" data-tip="Anno di costruzione">
                                <input
                                  value={building?.yearOfConstruction}
                                  id="year"
                                  type="number"
                                  placeholder="Anno di costruzione"
                                  className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                  disabled
                                />
                              </label>
                              <label className="relative w-32 mb-8 tooltip tooltip-primary" htmlFor="area" data-tip="Superficie mq.">
                                <input
                                  value={building?.area}
                                  id="area"
                                  type="number"
                                  placeholder="Superficie mq"
                                  className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-start relative py-8 w-full">
                          <h1 className="text-xl font-semibold sm:mr-8 mr-0 mb-4 sm:mb-0">
                            Titolo di possesso:
                          </h1>
                          <div>
                            <div className="card">
                              <Controller
                                name="propertyDeed"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.propertyDeed === PropertyDeed.PROPRIETARIO_COMPROPRIETARIO}
                                          disabled={!(building && building.propertyDeed === PropertyDeed.PROPRIETARIO_COMPROPRIETARIO)}
                                          value={PropertyDeed.PROPRIETARIO_COMPROPRIETARIO}
                                        />
                                        <span className="label-text font-medium">
                                          Proprietario o comproprietario
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.propertyDeed === PropertyDeed.DETENTORE_CODETENTORE}
                                          disabled={!(building && building.propertyDeed === PropertyDeed.DETENTORE_CODETENTORE)}
                                          value={PropertyDeed.DETENTORE_CODETENTORE}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Detentore o co-detentore (es.
                                          locatario, comodatario,
                                          usufruttuario, ecc.)
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.propertyDeed === PropertyDeed.FAMILIARE_CONVIVENTE}
                                          disabled={!(building && building.propertyDeed === PropertyDeed.FAMILIARE_CONVIVENTE)}
                                          value={PropertyDeed.FAMILIARE_CONVIVENTE}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Familiare convivente con il
                                          possessore o con il detentore
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.propertyDeed === PropertyDeed.CONDOMINIO}
                                          disabled={!(building && building.propertyDeed === PropertyDeed.CONDOMINIO)}
                                          value={PropertyDeed.CONDOMINIO}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Condominio
                                        </span>
                                      </label>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-start relative py-8 bg-gray-200 w-full">
                          <h1 className="text-xl font-semibold sm:mr-8 mr-0 mb-4 sm:mb-0">
                            Tipologia edilizia:
                          </h1>
                          <div>
                            <div className="card">
                              <Controller
                                name="buildingType"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.buildingType === BuildingType.EDIFICIO_IN_LINEA}
                                          disabled={!(building && building.buildingType === BuildingType.EDIFICIO_IN_LINEA)}
                                          value={BuildingType.EDIFICIO_IN_LINEA}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Edificio in linea e condominio oltre
                                          i tre piani fuori terra
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.buildingType === BuildingType.EDIFICIO_A_SCHIERA}
                                          disabled={!(building && building.buildingType === BuildingType.EDIFICIO_A_SCHIERA)}
                                          value={BuildingType.EDIFICIO_A_SCHIERA}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Edificio a schiera e condominio fino
                                          a tre piani
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.buildingType === BuildingType.COSTRUZIONE_ISOLATA}
                                          disabled={!(building && building.buildingType === BuildingType.COSTRUZIONE_ISOLATA)}
                                          value={BuildingType.COSTRUZIONE_ISOLATA}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Costruzione isolata (es. mono o
                                          plurifamiliare)
                                        </span>
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cursor-pointer label justify-start">
                                        <input
                                          type="radio"
                                          className="radio radio-primary mr-4 cursor-default"
                                          {...field}
                                          checked={building && building.buildingType === BuildingType.ALTRO}
                                          disabled={!(building && building.buildingType === BuildingType.ALTRO)}
                                          value={BuildingType.ALTRO}
                                        />
                                        <span className="label-text mr-4 font-medium">
                                          Altro
                                        </span>
                                      </label>
                                    </div>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-between w-full">
                          <h1 className="text-xl font-semibold sm:mr-8 mr-0 sm:w-2/4">
                            Pratica edilizia:
                          </h1>
                          <div className="flex w-full items-center my-8">
                            <h1 className="text-xl font-semibold mr-8">
                              N°
                            </h1>
                            <label className="relative w-full sm:mr-8 mr-0 sm:my-0 my-4 tooltip tooltip-primary" htmlFor="buildingPractice" data-tip="Numero di pratica">
                              <input
                                value={building?.buildingPractice}
                                id="buildingPractice"
                                type="text"
                                placeholder="Numero pratica"
                                className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                                disabled
                              />
                            </label>
                          </div>
                          <div className="flex w-full items-center">
                            <h1 className="text-xl font-semibold mr-8">
                              del
                            </h1>
                            <div className="relative w-full">
                              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <svg
                                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </div>
                              <label className="tooltip tooltip-primary" data-tip="Data di emissione">
                                <Controller
                                  name="buildingPracticeDate"
                                  control={control}
                                  render={({ field, fieldState: { invalid, error } }) => (
                                    <div>
                                      <DatePicker
                                        className="hiddenBtn h-12 bg-info sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-default text-white font-semibold"
                                        value={building ? new Date(building?.buildingPracticeDate)
                                          : null}
                                        onChange={(value: Date) => setValue('buildingPracticeDate', value && value.toISOString())}
                                        disabled
                                      />
                                    </div>
                                  )}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* footer */}
                  </div>
                </form>
                )}
                {(((selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate') && !building) || (serviceStateBuild === 'rejected' && building && selectedError))
                && (
                  <form>
                    <div className="relative overflow-y-auto">
                      <div className="flex flex-col">
                        <div className="flex flex-col items-start justify-between">
                          <div className="bg-gray-200 w-full">
                            <div className="flex items-center p-6 justify-between flex-col sm:flex-row">
                              <h1 className="text-xl font-semibold sm:mr-8 w-full mr-0 sm:mb-0 mb-4">
                                Dati immobile:
                              </h1>
                              <div className="relative w-full sm:mr-8 mr-0 sm:my-4 my-8">
                                <input
                                  type="number"
                                  placeholder="Foglio"
                                  className={errors.sheet ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                  {...register('sheet', {
                                    required: true,
                                  })}
                                />
                                {errors.sheet && (
                                <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                  {errors.sheet.message}
                                </span>
                                )}
                              </div>
                              <div className="relative w-full sm:mr-8 mr-0 sm:my-0 my-4 mb-12">
                                <input
                                  type="number"
                                  placeholder="Particella"
                                  className={errors.particle ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                  {...register('particle', {
                                    required: true,
                                  })}
                                />
                                {errors.particle && (
                                <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                  {errors.particle.message}
                                </span>
                                )}
                              </div>
                              <input
                                type="number"
                                placeholder="Subalterno"
                                className="input input-primary input-bordered w-full"
                                {...register('subordinate', {
                                  required: false,
                                })}
                              />
                            </div>
                            <div className="flex sm:items-center p-6 justify-end sm:flex-row flex-col">
                              <div className="relative w-full sm:w-72 sm:mr-8 mr-0 sm:mb-0 mb-4">
                                <input
                                  type="text"
                                  placeholder="Comune"
                                  className={errors.city ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                  {...register('city', {
                                    required: true,
                                  })}
                                />
                                {errors.city && (
                                <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                  {errors.city.message}
                                </span>
                                )}
                              </div>
                              <div className="flex my-8 sm:my-0">
                                <div className="relative w-24 mr-8">
                                  <input
                                    type="text"
                                    placeholder="CAP"
                                    className={errors.postalCode ? 'input input-primary input-bordered border-red-500 border-2 w-full' : 'input input-primary input-bordered w-full'}
                                    {...register('postalCode', {
                                      required: true,
                                    })}
                                  />
                                  {errors.postalCode && (
                                  <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                    {errors.postalCode.message}
                                  </span>
                                  )}
                                </div>
                                <div className="relative w-24">
                                  <input
                                    type="text"
                                    placeholder="Prov."
                                    className={errors.province ? 'input input-primary input-bordered border-red-500 border-2 w-full' : 'input input-primary input-bordered w-full'}
                                    {...register('province', {
                                      required: true,
                                    })}
                                  />
                                  {errors.province && (
                                  <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                    {errors.province.message}
                                  </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-col sm:flex-row sm:items-start p-6 justify-end">
                              <div className="flex flex-row md:flex-row sm:flex-col flex-wrap">
                                <div className="relative w-64 sm:mr-8 mr-4 mb-12">
                                  <input
                                    type="text"
                                    placeholder="Indirizzo"
                                    className={errors.address ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                    {...register('address', {
                                      required: true,
                                    })}
                                  />
                                  {errors.address && (
                                  <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                    {errors.address.message}
                                  </span>
                                  )}
                                </div>
                              </div>
                              <div className="flex flex-row md:flex-row sm:flex-col flex-wrap">
                                <div className="w-38 sm:mr-8 mr-4 mb-12 relative">
                                  <input
                                    type="number"
                                    placeholder="Anno di costruzione"
                                    className={errors.yearOfConstruction ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                    {...register('yearOfConstruction', {
                                      required: false,
                                    })}
                                  />
                                  {errors.yearOfConstruction && (
                                  <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                    {errors.yearOfConstruction.message}
                                  </span>
                                  )}
                                </div>
                                <div className="relative w-32 mb-8">
                                  <input
                                    type="number"
                                    placeholder="Superficie mq"
                                    className={errors.area ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                    {...register('area', {
                                      required: true,
                                    })}
                                  />
                                  {errors.area && (
                                  <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                    {errors.area.message}
                                  </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-start relative py-8 w-full">
                            <h1 className="text-xl font-semibold sm:mr-8 mr-0 mb-4 sm:mb-0">
                              Titolo di possesso:
                            </h1>
                            <div>
                              {errors.propertyDeed && (
                              <span className="absolute top-2 mt-1 text-sm text-red-500 font-semibold">
                                {errors.propertyDeed.message}
                              </span>
                              )}
                              <div className="card">
                                <Controller
                                  name="propertyDeed"
                                  control={control}
                                  render={({ field, fieldState: { invalid, error } }) => (
                                    <>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.propertyDeed ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.propertyDeed === PropertyDeed.PROPRIETARIO_COMPROPRIETARIO}
                                            value={PropertyDeed.PROPRIETARIO_COMPROPRIETARIO}
                                          />
                                          <span className="label-text font-medium">
                                            Proprietario o comproprietario
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.propertyDeed ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.propertyDeed === PropertyDeed.DETENTORE_CODETENTORE}
                                            value={PropertyDeed.DETENTORE_CODETENTORE}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Detentore o co-detentore (es.
                                            locatario, comodatario,
                                            usufruttuario, ecc.)
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.propertyDeed ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.propertyDeed === PropertyDeed.FAMILIARE_CONVIVENTE}
                                            value={PropertyDeed.FAMILIARE_CONVIVENTE}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Familiare convivente con il
                                            possessore o con il detentore
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.propertyDeed ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.propertyDeed === PropertyDeed.CONDOMINIO}
                                            value={PropertyDeed.CONDOMINIO}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Condominio
                                          </span>
                                        </label>
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-start relative py-8 bg-gray-200 w-full">
                            <h1 className="text-xl font-semibold sm:mr-8 mr-0 mb-4 sm:mb-0">
                              Tipologia edilizia:
                            </h1>
                            <div>
                              {errors.buildingType && (
                              <span className="absolute top-2 mt-1 text-sm text-red-500 font-semibold">
                                {errors.buildingType.message}
                              </span>
                              )}
                              <div className="card">
                                <Controller
                                  name="buildingType"
                                  control={control}
                                  render={({ field, fieldState: { invalid, error } }) => (
                                    <>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.buildingType ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.buildingType === BuildingType.EDIFICIO_IN_LINEA}
                                            value={BuildingType.EDIFICIO_IN_LINEA}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Edificio in linea e condominio oltre
                                            i tre piani fuori terra
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.buildingType ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.buildingType === BuildingType.EDIFICIO_A_SCHIERA}
                                            value={BuildingType.EDIFICIO_A_SCHIERA}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Edificio a schiera e condominio fino
                                            a tre piani
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.buildingType ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.buildingType === BuildingType.COSTRUZIONE_ISOLATA}
                                            value={BuildingType.COSTRUZIONE_ISOLATA}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Costruzione isolata (es. mono o
                                            plurifamiliare)
                                          </span>
                                        </label>
                                      </div>
                                      <div>
                                        <label className="cursor-pointer label justify-start">
                                          <input
                                            type="radio"
                                            className={errors.buildingType ? 'radio radio-primary mr-4 border-red-500 border-2' : 'radio radio-primary mr-4'}
                                            {...field}
                                            defaultChecked={building && building.buildingType === BuildingType.ALTRO}
                                            value={BuildingType.ALTRO}
                                          />
                                          <span className="label-text mr-4 font-medium">
                                            Altro
                                          </span>
                                        </label>
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-between w-full">
                            <h1 className="text-xl font-semibold sm:mr-8 mr-0 sm:w-2/4">
                              Pratica edilizia:
                            </h1>
                            <div className="flex w-full items-center my-8">
                              <h1 className="text-xl font-semibold mr-8">
                                N°
                              </h1>
                              <div className="relative w-full sm:mr-8 mr-0 sm:my-0 my-4">
                                <input
                                  type="text"
                                  placeholder="Numero pratica"
                                  className={errors.buildingPractice ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                                  {...register('buildingPractice', {
                                    required: true,
                                  })}
                                />
                                {errors.buildingPractice && (
                                <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                  {errors.buildingPractice.message}
                                </span>
                                )}
                              </div>
                            </div>
                            <div className="flex w-full items-center">
                              <h1 className="text-xl font-semibold mr-8">
                                del
                              </h1>
                              <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <svg
                                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </div>
                                <Controller
                                  name="buildingPracticeDate"
                                  control={control}
                                  render={({ field, fieldState: { invalid, error } }) => (
                                    <div>
                                      <DatePicker
                                        className={errors.buildingPracticeDate ? 'border-red-500 border-2 h-12 bg-gray-50 text-primary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' : 'h-12 bg-gray-50 border border-primary text-primary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'}
                                        value={field.value && new Date(field.value) || null}
                                        onChange={(value: Date) => setValue('buildingPracticeDate', value && value.toISOString())}
                                      />
                                    </div>
                                  )}
                                />
                                {errors.buildingPracticeDate && (
                                <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                                  {errors.buildingPracticeDate.message}
                                </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* footer */}
                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          onClick={handleSubmit(onSubmitHandler)}
                          className="btn w-full md:w-48 btn-secondary rounded-full my-6 py-3 px-6 font-bold border-0"
                          type="button"
                        >
                          Salva
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </div>
      ) : null}
    </div>
  );
};

export default Build;

import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Loader from './components/Loader';
import PrivateRoute from './components/PrivateRoute';
import Login from './features/Auth/pages/Login';
import { selectIsLoading } from './features/Common';
import Informations from './features/Informations/pages';
import Orders from './features/Orders/pages/index';
import './index.css';

const App: FunctionComponent = () => {
  const isLoading = useSelector(selectIsLoading);
  return (
    <>
      <section className={isLoading && ('') || ('')}>
        <div className={isLoading && ('overlay') || ('')} />
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/informations"
              element={(
                <PrivateRoute>
                  <Informations />
                </PrivateRoute>
              )}
            />
            <Route
              path="/"
              element={(
                <PrivateRoute>
                  <Informations />
                </PrivateRoute>
              )}
            />
            <Route
              path="/orders"
              element={(
                <PrivateRoute>
                  <Orders />
                </PrivateRoute>
              )}
            />
          </Routes>
        </Router>

      </section>
      <Loader active={isLoading} />
    </>
  );
};

export default App;

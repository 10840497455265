import React, { FunctionComponent, useEffect, useState } from 'react';
import { delay } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from 'src/features/Auth';
import { selectIsAbleToSend, actions as commonActions } from 'src/features/Common';
import Header from 'src/features/Informations/components/header';
import {
  actions as customersActions, selectError, selectStateBuildService,
  selectStateRegistryService, selectIsLoading as selectIsLoadingCustomer,
} from '../../Customers';
import {
  selectIsLoading, selectOrders, selectSelectedOrder, selectStateDocumentService,
  actions as ordersActions,
  selectOrderError,
  selectFilters,
} from '..';
import OrderDetail from '../components/OrderDetail';
import OrderList from '../components/OrderList';
import OrderManagement from '../components/OrderManagement';
import { getOrder, getOrderDocuments, getOrders } from '../services';
import ErrorsModal from '../../Auth/components/errorsModal';
import SuccessModal from '../../Auth/components/successModal';

const OrdersPage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const [showErrorsModal, setShowErrorsModal] = useState<boolean>(true);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  const orders = useSelector(selectOrders);
  const isLoading = useSelector(selectIsLoading);
  const customerIsLoading = useSelector(selectIsLoadingCustomer);
  const selectedOrder = useSelector(selectSelectedOrder);
  const ableToSend = useSelector(selectIsAbleToSend);
  const token = useSelector(selectToken);
  const selectedError = useSelector(selectError);
  const selectedOrderError = useSelector(selectOrderError);
  const registryState = useSelector(selectStateRegistryService);
  const buildState = useSelector(selectStateBuildService);
  const orderDocumentsState = useSelector(selectStateDocumentService);
  const filterOrders = useSelector(selectFilters);

  useEffect(() => {
    if (token) {
      dispatch(getOrders());
    }
  }, [dispatch, token, filterOrders]);

  useEffect(() => {
    if (isLoading || customerIsLoading) {
      dispatch(commonActions.setIsLoading(true));
    } else {
      delay(() => dispatch(commonActions.setIsLoading(false)), 500);
    }
  }, [isLoading, customerIsLoading]);

  useEffect(() => {
    if (selectedOrder && isLoading === false) {
      dispatch(getOrder(selectedOrder.id));
    }
  }, [isLoading]);

  useEffect(() => {
    if (selectedError || selectedOrderError) {
      setShowErrorsModal(true);
    }
  }, [selectedError, selectedOrderError]);

  const onSubmit = () => {
    const form = document.getElementById('formUploadFilesButtom');
    form?.click();
  };

  const onModalOpen = () => {
    setShowSuccessModal(false);
    if (registryState !== 'pending') {
      dispatch(customersActions.setStateRegistryService());
    }
    if (buildState !== 'pending') {
      dispatch(customersActions.setStateBuildService());
    }
    if (orderDocumentsState !== 'pending') {
      dispatch(ordersActions.setStateDocumentService());
      if (selectedOrder?.status === 'opened' || selectedOrder?.status === 'installed') {
        dispatch(getOrderDocuments(selectedOrder?.id || -1));
      }
    }
  };

  useEffect(() => {
    setTimeout(onModalOpen, 2000);
  }, [showSuccessModal]);

  useEffect(() => {
    if (registryState === 'fullfilled' || buildState === 'fullfilled' || orderDocumentsState === 'fullfilled') {
      setShowSuccessModal(true);
    }
  }, [registryState, buildState, orderDocumentsState]);

  return (
    <div className="mt-32 px-2 py-4 h-orderPage md:px-12 md:py-8">
      <Header />
      <div className="flex flex-col lg:flex-row items-center lg:items-start">
        <div>
          <OrderList
            orders={orders?.data || []}
            onPagination={(page) => dispatch(getOrders({ page, perPage: 5 }))}
          />
          <OrderManagement />
        </div>
        <div className={selectedOrder ? 'w-full self-start lg:mr-12' : 'w-full self-center lg:mr-12'}>
          {selectedOrder && (
            <>
              <OrderDetail order={selectedOrder} key={selectedOrder.id} />
              <div className="flex justify-end">
                <label
                  htmlFor="formUploadFiles"
                  className="w-full text-right"
                >
                  {selectedOrder.status !== 'opened' && selectedOrder.status !== 'rejected' && selectedOrder.status !== 'installed' && selectedOrder.status !== 'closed'
                  && (
                  <input
                    type="submit"
                    className="btn w-full md:w-48 btn-secondary rounded-full my-6 py-3 px-6 font-bold border-0"
                    onClick={onSubmit}
                    disabled={!ableToSend}
                  />
                  )}
                </label>
              </div>
            </>
          )}
          {!selectedOrder && (
            <div className="flex justify-center">
              <p className="p-5 shadow-md">
                Nessun ordine selezionato
              </p>
            </div>
          )}
        </div>
        {showErrorsModal && (selectedError || selectedOrderError)
          && <ErrorsModal onClose={() => setShowErrorsModal(false)} />}
        {registryState === 'fullfilled' && showSuccessModal
          && <SuccessModal message="Anagrafica salvata correttamente" />}
        {buildState === 'fullfilled' && showSuccessModal
          && <SuccessModal message="Immobile salvato correttamente" />}
        {orderDocumentsState === 'fullfilled' && showSuccessModal
          && <SuccessModal message="Documenti inviati correttamente" />}
      </div>
    </div>
  );
};

export default OrdersPage;

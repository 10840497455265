import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import {
  Estimate, Order, OrderFilters, OrdersState,
} from '.';
import { RootState } from '../../store';
import {
  changeStateToOpened, getOrders, changeStateToEstimate,
  getOrderDocuments, changeStateToInstalled, printOffer, getOrder,
} from './services';

const initialState: OrdersState = {
  orders: null,
  documents: null,
  loading: false,
  error: null,
  selected: null,
  isSended: false,
  estimate: null,
  serviceDocumentState: 'pending',
  totalStatusOrders: false,
  printOffer: null,
  filters: null,
  lastPage: null,
  filterActive: false,
};

export const selectIsLoading = createSelector(
  (state: RootState) => state.orders.loading,
  (loading) => loading,
);

export const selectOrderError = createSelector(
  (state: RootState) => state.orders.error,
  (error) => error,
);

export const selectOrders = createSelector(
  (state: RootState) => state.orders.orders,
  (orders) => orders,
);

export const selectDocuments = createSelector(
  (state: RootState) => state.orders.documents,
  (documents) => documents,
);

export const selectSelectedOrder = createSelector(
  (state: RootState) => state.orders.selected,
  (order) => order,
);

export const selectStateDocumentService = createSelector(
  (state: RootState) => state.orders.serviceDocumentState,
  (serviceDocumentState) => serviceDocumentState,
);

export const selectFilters = createSelector(
  (state: RootState) => state.orders.filters,
  (filters) => filters,
);

export const selectTotalStatusOrders = createSelector(
  (state: RootState) => state.orders.totalStatusOrders,
  (totalStatusOrders) => totalStatusOrders,
);

export const selectLastPage = createSelector(
  (state: RootState) => state.orders.lastPage,
  (lastPage) => lastPage,
);

export const selectFilterActive = createSelector(
  (state: RootState) => state.orders.filterActive,
  (filterActive) => filterActive,
);
const { actions, name, reducer } = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setEstimate: (state: OrdersState, action: PayloadAction<Estimate>) => {
      state.estimate = action.payload;
    },
    setStateDocumentService: (state: OrdersState) => {
      state.serviceDocumentState = 'pending';
    },
    resetSetSelectedOrder: (state: OrdersState) => {
      state.selected = null;
    },
    setFilters: (state: OrdersState, action: PayloadAction<OrderFilters>) => {
      state.filters = action.payload;
    },
    setResetFilters: (state: OrdersState) => {
      state.filters = null;
    },
    setFilterActive: (state: OrdersState) => {
      state.filterActive = true;
    },
    setResetFilterActive: (state: OrdersState) => {
      state.filterActive = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      if (state.filterActive === false) {
        state.loading = true;
      }
      state.error = null;
      state.totalStatusOrders = false;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      if (payload) {
        state.orders = payload.data;
        state.lastPage = payload.data.meta.last_page;
        state.loading = false;
        state.totalStatusOrders = true;
      } else {
        state.orders = null;
        state.loading = false;
      }
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.error as AxiosError;
      }
      state.loading = false;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getOrder.fulfilled, (state, { payload }) => {
      state.selected = payload.data;
      state.loading = false;
    });
    builder.addCase(getOrder.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.error as AxiosError;
      }
      state.loading = false;
    });
    builder.addCase(getOrderDocuments.pending, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getOrderDocuments.fulfilled, (state, { payload }) => {
      if (payload) {
        state.documents = payload.data;
        state.loading = false;
      } else {
        state.orders = null;
        state.loading = false;
      }
    });
    builder.addCase(getOrderDocuments.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.error as AxiosError;
      }
      state.loading = false;
    });
    builder.addCase(changeStateToEstimate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(changeStateToEstimate.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(changeStateToEstimate.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload as AxiosError;
      }
      state.loading = false;
    });
    builder.addCase(changeStateToOpened.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(changeStateToOpened.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.isSended = true;
      state.serviceDocumentState = 'fullfilled';
    });
    builder.addCase(changeStateToOpened.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload as AxiosError;
        state.serviceDocumentState = 'rejected';
      }
      state.loading = false;
    });
    builder.addCase(changeStateToInstalled.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(changeStateToInstalled.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.isSended = true;
      state.serviceDocumentState = 'fullfilled';
    });
    builder.addCase(changeStateToInstalled.rejected, (state, action) => {
      if (action.payload) {
        state.error = action.payload as AxiosError;
        state.serviceDocumentState = 'rejected';
      }
      state.loading = false;
    });
    builder.addCase(printOffer.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(printOffer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.printOffer = payload.data;
    });
    builder.addCase(printOffer.rejected, (state, action) => {
      state.error = action.error;
      state.loading = false;
    });
  },
});

export { reducer, actions, name };

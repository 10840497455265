/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSelectedOrder } from '..';
import { ReactComponent as Close } from '../../../assets/Icon-close.svg';

export interface RejectedProps {
  onClose: () => void
}

const RejectedModal: FunctionComponent<RejectedProps> = ({ onClose }) => {
  const selectedOrder = useSelector(selectSelectedOrder);

  return (
    <div className="modal modal-open flex justify-center items-center">
      <div className="modal-box">
        <h1 className="text-red-600 font-semibold">MOTIVAZIONE DEL RIFIUTO</h1>
        <div className="modal-action">
          <label
            htmlFor="rejected-modal"
            className="absolute top-4 right-8 cursor-pointer"
            onClick={onClose}
          >
            <Close />
          </label>
          <input type="checkbox" id="rejected-modal" className="modal-toggle" />
          <div className="ml-0 flex flex-col w-full">
            <div className="flex justify-start items-center text-lg font-medium">
              {selectedOrder?.rejectMotivation}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectedModal;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { ReactComponent as Check } from '../../../assets/Icon-check-circle.svg';

export interface SuccessProps {
    message: string
}

const SuccessModal: FunctionComponent<SuccessProps> = ({ message }) => (
  <div className="modal modal-open flex justify-center items-center">
    <div className="modal-box">
      <div>
        <input type="checkbox" id="rejected-modal" className="modal-toggle" />
        <div className="ml-0 flex flex-col w-full">
          <div className="flex justify-center items-center text-2xl font-semibold">
            <Check className="mr-4" />
            {message}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SuccessModal;

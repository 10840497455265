import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/header';

const Informations: FunctionComponent = () => (
  <div className="mt-32">
    <Header />
    <div className="pt-12 pb-4 w-4/5 my-0 mx-auto">
      <h1 className="text-center text-3xl font-semibold">
        Ecobonus: come funziona lo Sconto in Fattura?
      </h1>
      <h2 className="text-center text-2xl my-8">
        Con lo sconto in fattura ecobonus puoi risparmiare fino al 65% sull&apos;acquisto
        dei nostri prodotti senza dover attendere la detrazione fiscale in 10 anni.
      </h2>
      <p>
        La possibilità di usufruire dello Sconto in Fattura parte dall’opportunità che il
        Governo offre per le Detrazioni Fiscali del 65% per Riqualificazione Energetica o
        del 50% per il Bonus Casa. A seconda del tipo di intervento che desideri effettuare
        puoi usufruire di uno sconto del 65% o del 50%.
      </p>
      <ul className="list-disc list-inside">
        <li>
          Se vuoi sostituire il tuo vecchio generatore puoi usufruire dell&apos;
          <span className="font-bold">Ecobonus 65%</span>
        </li>
        <li>
          Se vuoi effettuare una nuova installazione puoi usufruire del
          <span className="font-bold"> Bonus casa 50%</span>
        </li>
      </ul>
      <p className="my-4">
        Per usufruire dello
        <span className="font-bold"> Sconto immediato in Fattura del 65% </span>
        è
        indispensabile che tu sia in possesso di uno o più generatori con potenza termica (o
        somma delle potenze termiche) nominale in riscaldamento equivalente o superiore alla
        potenza (o alla somma delle potenze) di quello/quelli da acquistare. In questo caso
        sceglierai il prodotto nella sezione
        <span className="font-bold"> SOSTITUZIONE.</span>
      </p>
      <p className="mb-4">
        Se invece non possiedi dei generatori da sostituire oppure le potenze dei vecchi non
        sono simili a quelle dei nuovi da acquistare, potrai utilizzare la procedura per lo
        <span className="font-bold"> Sconto immediato in Fattura al 50% </span>
        (solo per le persone fisiche). In questo caso sceglierai il prodotto nella sezione
        <span className="font-bold"> NUOVO IMPIANTO.</span>
      </p>
      <p>
        Dopo aver effettuato l’ordine online riceverai una e-mail con la richiesta dei
        documenti da caricare automaticamente sul nostro portale.
      </p>
      <ul className="list-disc list-inside">
        <li>Carta di Identità</li>
        <li>Tessera sanitaria</li>
        <li>Foto etichetta del vecchio generatore da sostituire</li>
        <li>Foto del vecchio generatore da sostituire</li>
        <li> Visura catastale dell&apos;immobile oggetto della detrazione</li>
      </ul>
      <p>
        Se hai scelto lo Sconto in Fattura del 50% NON dovrai caricare le foto dei vecchi
        generatori.
      </p>
      <p className="my-4">
        Nel passo successivo riceverai una e-mail con la procedura per effettuare il
        pagamento tramite
        <span className="font-bold"> Bonifico parlante per Detrazione Fiscale </span>
        della tipologia giusta per il beneficio utilizzabile e con le deleghe da
        sottoscrivere e rimandare firmate; è necessario reinviare i documenti completi
        firmati, non solo le loro ultime pagine.
      </p>
      <p>
        Dopo aver ricevuto l&apos;accredito e verificato la correttezza del bonifco,
        <span className="font-bold">
          {' '}
          effettueremo la spedizione di quanto hai acquistato
          {' '}
        </span>
        tramite corriere espresso.
      </p>
      <p className="my-4">
        Lo Sconto in Fattura è una grande opportunità che ti permette di effettuare i tuoi
        acquisti su Climamarket a prezzi estremamente convenienti. Ti informiamo che
        <span className="font-bold">
          {' '}
          la gestione di tutti i vari passaggi delle pratiche richiede alcuni giorni
          {' '}
        </span>
        e che
        {' '}
        <span className="font-bold">
          {' '}
          i prodotti si riterranno realmente impegnati solo a seguito dell&apos;effettivo
          accredito del pagamento.
          {' '}
        </span>
      </p>
      <p>
        Per non rallentare la gestione della tua pratica ti chiediamo di seguire questa
        semplice procedura.
      </p>
    </div>
    <div className="my-0 mx-auto w-4/5">
      <Link
        className="btn btn-secondary rounded-full mt-4 py-3 px-6 font-bold border-0 w-full mb-12"
        to="/orders"
      >
        Avanti
      </Link>
    </div>
  </div>
);

export default Informations;

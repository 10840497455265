import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions as commonActions } from '../../Common';
import { selectIsLogged, selectError } from '..';
import StoreLogo from '../../../assets/3bstorelogo.png';
import StoreLogoMini from '../../../assets/3bstorelogomini.png';
import LoginForm, { LoginFormData } from '../components/LoginForm';
import { login } from '../services';

const Login: FunctionComponent = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector(selectIsLogged);
  const isError = useSelector(selectError);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogged) {
      dispatch(commonActions.setIsLoading(false));
      navigate('/informations');
    }
    if (isError) {
      dispatch(commonActions.setIsLoading(false));
    }
  }, [dispatch, isLogged, navigate, isError]);

  const onLogin = async (values: LoginFormData) => {
    dispatch(commonActions.setIsLoading(true));
    dispatch(login(values));
  };

  return (
    <div className="p-6 md:p-16 bg-gradient-to-r from-primary to-primary-focus lex w-full h-screen flex flex-row justify-around items-center">
      <div className="w-96 flex-col md:flex-row justify-center items-center text-white text-center">
        <div className="my-0 mx-auto w-48">
          <img src={StoreLogo} alt="3bStore Logo" className="w-full" />
        </div>
        <p className="text-lg mt-4">Accedi alla piattaforma Bonus in fattura</p>
        <div className="text-2xl my-12">LOGIN</div>
        <LoginForm onSubmit={onLogin} />
        <a
          href="https://3bstore.it/recupero-password"
          target="_blank"
          className="text-base-200 mt-12 inline-block cursor-pointer"
          rel="noreferrer"
        >
          Hai dimenticato la password?
        </a>
      </div>
      <div className="hidden md:block ml-8">
        <img src={StoreLogoMini} alt="3bStore Logo Mini" />
      </div>
    </div>
  );
};

export default Login;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { Estimate } from '..';
import { ReactComponent as Close } from '../../../assets/Icon-close.svg';

export interface EstimateProps {
  estimate: Estimate;
  onClose: () => void
  onSubmit: (values: Estimate) => void
}

const ExtraInstallation: FunctionComponent<EstimateProps> = ({
  estimate,
  onClose,
  onSubmit,
}) => {
  // const [values, setValues] = useState<Estimate>(estimate);
  const [values, setValues] = useState<Estimate>({
    extraAmount: 0,
    extraAmountMotivation: '',
  });

  useEffect(() => {
    if (estimate.extraAmountMotivation !== 'standard' && estimate.initialExtraAmount) {
      setValues({
        extraAmount: estimate.extraAmount - estimate.initialExtraAmount,
        extraAmountMotivation: estimate.extraAmountMotivation,
      });
    }
  }, [estimate]);

  const submitHandler = useCallback(() => {
    onSubmit(values);
    onClose();
  }, [onClose, onSubmit, values]);

  return (
    <div className="modal flex justify-center items-center">
      <div className="modal-box">
        <div className="modal-action">
          <label htmlFor="estimate-modal" className="absolute top-4 right-8 cursor-pointer" onClick={onClose}>
            <Close />
          </label>
          <input type="checkbox" id="estimate-modal" className="modal-toggle" />
          <div className="flex flex-col w-full">
            <div className="flex justify-start items-center text-lg font-medium">
              <label className="mr-4">Supplemento: </label>
              <input value={values.extraAmount || ''} onChange={({ target: { value } }) => setValues({ ...values, extraAmount: Number(value) })} type="number" className="input input-primary" min={0} max={999} />
              <label className="ml-2">€</label>
            </div>
            <div className="flex justify-start items-center text-lg my-6 font-medium">
              <label className="mr-4">Motivazione: </label>
              <textarea value={values.extraAmountMotivation || ''} onChange={({ target: { value } }) => setValues({ ...values, extraAmountMotivation: value })} className="textarea textarea-primary h-24 textarea-bordered w-full" />
            </div>
            <div className="flex justify-end">
              <label className="btn btn-secondary font-bold w-full">
                <button onClick={submitHandler} type="button" className="font-semibold text-lg">SALVA</button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtraInstallation;

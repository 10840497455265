import React, { FunctionComponent, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, RouteProps, Route } from 'react-router-dom';
import { selectIsLogged } from 'src/features/Auth';

interface PrivateRouteProps {
    children: JSX.Element
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({ children }) => {
  const isLogged = useSelector(selectIsLogged);

  return isLogged ? <>{children}</> : <Navigate to="/login" />;
};

export default PrivateRoute;

/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, {
  ChangeEvent, createRef, FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { formatCurrencyNumber } from 'src/helpers';
import { useSelector } from 'react-redux';
import { selectIsDifferentOrder } from 'src/features/Common';
import {
  PdcDocuments,
  PhotoOldGenerator, Product, selectDocuments, selectSelectedOrder,
} from '..';
import { ReactComponent as Check } from '../../../assets/Icon-check.svg';
import { ReactComponent as GeneratorWhite } from '../../../assets/Icon-generator-white.svg';
import { ReactComponent as Generator } from '../../../assets/Icon-generator.svg';
import { ReactComponent as LabelWhite } from '../../../assets/Icon-label-white.svg';
import { ReactComponent as Label } from '../../../assets/Icon-label.svg';
import { ReactComponent as Upload } from '../../../assets/Icon-upload.svg';
import { ReactComponent as Eye } from '../../../assets/Icon-eye.svg';
import { selectCustomer } from '../../Customers';

export type ProductDetailProps = {
    product: Product
    id: number
    index: number
    onDocumentsComplete: (a: PhotoOldGenerator) => void
    onPdcDocumentsComplete: (a: PdcDocuments) => void
}

const ProductDetail: FunctionComponent<ProductDetailProps> = ({
  product,
  id,
  index,
  onDocumentsComplete,
  onPdcDocumentsComplete,
}) => {
  const isDifferentOrder = useSelector(selectIsDifferentOrder);
  const [oldProdExist, setOldProdExist] = useState<boolean>(false);
  const [oldProdLabelExist, setOldProdLabelExist] = useState<boolean>(false);
  const [pdcExist, setPdcExist] = useState<boolean>(false);

  const selectedOrder = useSelector(selectSelectedOrder);
  const selectedCustomer = useSelector(selectCustomer);
  const orderDocuments = useSelector(selectDocuments);
  const input1Ref = useRef();
  useEffect(() => {
    setOldProdExist(false);
    setOldProdLabelExist(false);
    setPdcExist(false);
    if (orderDocuments) {
      const oldProdFile = orderDocuments
        ?.filter((document) => document.type === 'old-prod')
        .map((e) => e);
      if (oldProdFile.length > 0 && selectedOrder?.status === 'opn-rejected') {
        setOldProdExist(true);
      }
    }
    if (orderDocuments) {
      const oldProdLabelFile = orderDocuments
        ?.filter((document) => document.type === 'old-prod-label')
        .map((e) => e);
      if (oldProdLabelFile.length > 0 && selectedOrder?.status === 'opn-rejected') {
        setOldProdLabelExist(true);
      }
    }
    if (orderDocuments) {
      const pdc = orderDocuments
        ?.filter((document) => document.type === 'pdc')
        .map((e) => e);
      if (pdc.length > 0 && selectedOrder?.status === 'inst-rejected') {
        setPdcExist(true);
      }
    }
  }, [orderDocuments]);

  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [documents, setDocuments] = useState<PhotoOldGenerator>({});
  const [pdcDocuments, setPdcDocuments] = useState<PdcDocuments>({});

  useEffect(() => {
    if (isDifferentOrder) {
      setDocuments({
        id,
        index,
        photoLabelGenerator: null,
        photoOldGenerator: null,
      });
    }
  }, [isDifferentOrder]);

  const onInputPdc = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const obj: PdcDocuments = {
        id: product.id,
        index,
        pdc: e.target.files[0],
      };
      setPdcDocuments(obj);
    }
  };

  useEffect(() => {
    if (pdcDocuments && pdcDocuments.pdc) {
      onPdcDocumentsComplete(pdcDocuments);
    }
  }, [pdcDocuments]);

  useEffect(() => {
    if (
      !isCompleted
            && documents
            && documents.photoLabelGenerator
            && documents.photoOldGenerator
    ) {
      setIsCompleted(true);
      onDocumentsComplete(documents);
    }
  }, [onDocumentsComplete, documents, isCompleted]);

  const onInputPhotoOld = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setDocuments({ ...documents, photoOldGenerator: e.target.files[0] });
    }
  };

  const onInputPhotoLabelOld = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setDocuments({ ...documents, photoLabelGenerator: e.target.files[0] });
    }
  };

  const onViewPhotoOldFile = (value: number) => {
    const arrayDocuments: string[] = [];
    const arrayIndexProducts: number[] = [];

    // eslint-disable-next-line array-callback-return
    selectedOrder?.products?.map((e, i) => {
      if (e.bonusType === 'ecobonus - sostituzione') {
        arrayIndexProducts.push(i);
      }
    });
    orderDocuments?.filter((document) => document.type === 'old-prod')
      .forEach((document) => {
        if (document.url) {
          arrayDocuments.push(document.url);
        }
      });

    arrayIndexProducts.map((e, i) => {
      if (value === e) {
        return window.open(arrayDocuments[i]);
      }
      return null;
    });
  };
  const onViewPhotoOldLabelFile = (value: number) => {
    const arrayDocuments: string[] = [];
    const arrayIndexProducts: number[] = [];

    // eslint-disable-next-line array-callback-return
    selectedOrder?.products?.map((e, i) => {
      if (e.bonusType === 'ecobonus - sostituzione') {
        arrayIndexProducts.push(i);
      }
    });
    orderDocuments?.filter((document) => document.type === 'old-prod-label')
      .forEach((document) => {
        if (document.url) {
          arrayDocuments.push(document.url);
        }
      });

    arrayIndexProducts.map((e, i) => {
      if (value === e) {
        return window.open(arrayDocuments[i]);
      }
      return null;
    });
  };

  const onViewPdcFile = (value: number) => {
    const arrayDocuments: string[] = [];
    const arrayIndexProducts: number[] = [];

    // eslint-disable-next-line array-callback-return
    selectedOrder?.products?.map((e, i) => {
      if (e.bonusType === 'ecobonus - sostituzione') {
        arrayIndexProducts.push(i);
      }
    });
    orderDocuments?.filter((document) => document.type === 'pdc')
      .forEach((document) => {
        if (document.url) {
          arrayDocuments.push(document.url);
        }
      });

    arrayIndexProducts.map((e, i) => {
      if (value === e) {
        return window.open(arrayDocuments[i]);
      }
      return null;
    });
  };

  return (
    <div className="h-auto md:maxProduct xs:w-full border-2 bg-white border-gray-300 shadow-md flex flex-col items-center justify-center p-8 text-center m-4 my-8 relative">
      <div>
        <h1 className="font-semibold text-lg">{product.reference}</h1>
        <h1 className="my-4">{product.name}</h1>
        <div className="flex flex-col items-center mb-4">
          <div className="text-sm">
            <span>Senza sconto ecobonus </span>
            {formatCurrencyNumber(product.price)}
            €
          </div>
          <div className="text-xl my-2">
            Con sconto ecobonus
            <span className="font-semibold">
              {' '}
              {formatCurrencyNumber(product.priceDiscount)}
              €
            </span>
          </div>
          <div className="text-sm">
            <span>Installazione standard </span>
            {formatCurrencyNumber(product.extraAmount)}
            €
          </div>
        </div>
        <div className="bg-green-700 absolute -bottom-4 -right-4 text-white px-6 py-3 text-2xl font-medium priceBox">
          {formatCurrencyNumber(product.discountWithExtraAmount)}
          €
        </div>
        <div
          className={
                        product.bonusType === 'ecobonus - sostituzione'
                          ? 'badge bg-info text-white text-sm border-0 absolute top-2 -right-4 mb-2 mr-2 bonusLabel bonusLabelInfo'
                          : 'badge bg-secondary-focus text-white text-sm border-0 absolute top-2 -right-4 mb-2 mr-2 bonusLabel bonusLabelSecondary'
                    }
        >
          {product.bonusType}
        </div>
        <div
          className={
                        product.bonusType === 'ecobonus - sostituzione'
                          ? 'percentLabel percentLabel65'
                          : 'percentLabel percentLabel50'
                    }
        />
        {/* <div className="absolute bottom-2 left-2 text-sm mb-1 ml-2">
        {`IVA ${product.taxAmount}%`}</div> */}
        <div className="absolute bottom-2 left-2 text-sm mb-1 ml-2">Tasse escluse</div>
        <div className="flex justify-center mb-4">
          {product.bonusType === 'ecobonus - sostituzione' && (
            <>
              {(selectedOrder?.status !== 'confirmed' && selectedOrder?.status !== 'installed' && selectedOrder?.status !== 'inst-rejected')
              && !(!selectedCustomer && selectedOrder?.status === 'rejected') && (
              <div className="flex justify-center">
                {!oldProdExist
                && (
                <label
                  data-tip="Foto del vecchio generatore"
                  className={
                                      documents.photoOldGenerator && selectedOrder?.status !== 'opened'
                                        ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-2'
                                        : ' btn bg-white text-primary border-primary border-2 m-2 tooltip tooltip-primary flex'
                                  }
                  htmlFor={`inputPhotoOldGenerator${product.id}.${index}`}
                >
                  <input
                    ref={input1Ref.current}
                    type={selectedOrder?.status !== 'opened' ? 'file' : 'button'}
                    onChange={
                                          selectedOrder?.status !== 'opened'
                                            ? (e) => onInputPhotoOld(e)
                                            : () => console.log('h')
                                      }
                    id={`inputPhotoOldGenerator${product.id}.${index}`}
                    onClick={
                                          selectedOrder?.status === 'opened' || selectedOrder?.status === 'rejected' || selectedOrder?.status === 'closed'
                                            ? () => onViewPhotoOldFile(index)
                                            : undefined
                                      }
                  />
                  {selectedOrder?.status === 'opened' || selectedOrder?.status === 'rejected' || selectedOrder?.status === 'closed' ? (
                    <Eye className="mr-4" />
                  ) : !documents.photoOldGenerator ? (
                    <Upload className="mr-4" />
                  ) : (
                    <Check className="mr-4" />
                  )}
                  {' '}
                  {!documents.photoOldGenerator || selectedOrder?.status === 'opened' ? <Generator /> : <GeneratorWhite />}
                </label>
                )}
              </div>
              )}
              {product.bonusType === 'ecobonus - sostituzione' && (
              <>
                {(selectedOrder?.status !== 'confirmed' && selectedOrder?.status !== 'installed' && selectedOrder?.status !== 'inst-rejected')
              && !(!selectedCustomer && selectedOrder?.status === 'rejected') && (
              <div className="flex justify-center">
                {!oldProdLabelExist
                && (
                <label
                  data-tip="Foto etichetta del vecchio generatore"
                  className={
                                      documents.photoLabelGenerator && selectedOrder?.status !== 'opened'
                                        ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-2'
                                        : ' btn bg-white text-primary border-primary border-2 m-2 tooltip tooltip-primary flex'
                                  }
                  htmlFor={`inputPhotoLabelGenrator${product.id}.${index}`}
                >
                  <input
                    type={selectedOrder?.status !== 'opened' ? 'file' : 'button'}
                    onChange={
                                          selectedOrder?.status !== 'opened'
                                            ? (e) => onInputPhotoLabelOld(e)
                                            : () => console.log('s')
                                      }
                    id={`inputPhotoLabelGenrator${product.id}.${index}`}
                    onClick={
                                          selectedOrder?.status === 'opened' || selectedOrder?.status === 'rejected' || selectedOrder?.status === 'closed'
                                            ? () => onViewPhotoOldLabelFile(index)
                                            : undefined
                                      }
                  />
                  {selectedOrder?.status === 'opened' || selectedOrder?.status === 'rejected' || selectedOrder?.status === 'closed' ? (
                    <Eye className="mr-4" />
                  ) : !documents.photoLabelGenerator ? (
                    <Upload className="mr-4" />
                  ) : (
                    <Check className="mr-4" />
                  )}
                  {' '}
                  {!documents.photoLabelGenerator || selectedOrder?.status === 'opened' ? <Label /> : <LabelWhite />}
                </label>
                )}
              </div>
                )}
              </>
              )}
              {(selectedOrder?.status === 'confirmed' || selectedOrder?.status === 'installed' || selectedOrder?.status === 'inst-rejected' || selectedOrder?.status === 'closed')
              && (
              <div className="flex mr-4 justify-center">
                {!pdcExist
                && (
                <label
                  data-tip="Schede tecniche pdc - conformità prodotto"
                  className={
                                      (pdcDocuments.pdc && selectedOrder?.status !== 'installed')
                                        ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-2'
                                        : ' btn bg-white text-primary border-primary border-2 m-2 tooltip tooltip-primary flex'
                                  }
                  htmlFor={`inputPdc${product.id}.${index}`}
                >
                  <input
                    type={selectedOrder?.status !== 'installed' ? 'file' : 'button'}
                    onChange={
                                          selectedOrder?.status !== 'installed'
                                            ? (e) => onInputPdc(e)
                                            : undefined
                                      }
                    id={`inputPdc${product.id}.${index}`}
                    onClick={
                                          selectedOrder?.status === 'installed' || selectedOrder?.status === 'closed'
                                            ? () => onViewPdcFile(index)
                                            : undefined
                                      }
                  />
                  {selectedOrder?.status === 'installed' || selectedOrder?.status === 'closed' ? (
                    <Eye className="mr-4" />
                  ) : !pdcDocuments.pdc ? (
                    <Upload className="mr-4" />
                  ) : (
                    <Check className="mr-4" />
                  )}
                  {' '}
                  D.CO
                </label>
                )}
              </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProductDetail;

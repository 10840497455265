/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-comment-textnodes */
import moment from 'moment';
import React, {
  FunctionComponent, useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  OrderStatus, Order, selectSelectedOrder, selectTotalStatusOrders, selectLastPage,
} from '..';

import { getOrder, getOrderDocuments, getOrders } from '../services';
import { actions } from '../state';
import { actions as customersActions } from '../../Customers';
import { ReactComponent as Filter } from '../../../assets/Icon-filter2.svg';
import { ReactComponent as Arrow } from '../../../assets/Icon-arrow.svg';
import { ReactComponent as Search } from '../../../assets/Icon-search.svg';

export type OrderListProps = {
    orders?: Order[];
    pagination?: { totalItems: number; perPage: number; }
    onPagination?: (page: number) => void;
}

const OrderList: FunctionComponent<OrderListProps> = ({ orders, pagination, onPagination = () => {} }) => {
  const [orderActive, setOrderActive] = useState<number>(0);
  const [pageOrderActive, setPageOrderActive] = useState<number>(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [statusOrder, setStatusOrder] = useState<string>('Tutti');
  const [showSearchOrders, setShowSearchOrders] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [OrdersInput, setOrdersInput] = useState<string>();
  const dispatch = useDispatch();
  const selectedOrder = useSelector(selectSelectedOrder);
  const selectedTotalStatusOrders = useSelector(selectTotalStatusOrders);
  const selectedLastPage = useSelector(selectLastPage);

  const nextPageHandler = useCallback(() => {
    if (selectedLastPage && page < selectedLastPage) {
      setPage(page + 1);
      onPagination(page + 1);
    }
  }, [onPagination, page]);

  const prevPageHandler = useCallback(() => {
    if (page > 1) {
      onPagination(page - 1);
      setPage(page - 1);
    }
  }, [onPagination, page]);

  useEffect(() => {
    if (selectedOrder?.building) {
      dispatch(customersActions.setCustomer(selectedOrder?.building.customer));
      dispatch(customersActions.setBuilding(selectedOrder?.building));
    } else {
      dispatch(customersActions.setCustomer(null));
      dispatch(customersActions.setBuilding(null));
    }
  }, [selectedOrder]);

  useEffect(() => {
    if (showSearchOrders) {
      dispatch(actions.setFilterActive());
      setStatusOrder('Tutti');
    } else {
      dispatch(actions.setResetFilterActive());
    }
    dispatch(actions.setResetFilters());
  }, [showSearchOrders]);

  // eslint-disable-next-line max-len
  /* useEffect(() => {
    if (selectedTotalStatusOrders) {
      setFilterSelected(orders?.filter((v, i, a) => a.findIndex((t) => (t.status === v.status)) === i).sort((a, b) => (a.status > b.status ? 1 : -1)));
    }
  }, [selectedTotalStatusOrders]); */

  const orderStatus = useMemo(() => Object.values(OrderStatus), []);

  const getOrderStateName = (order: string) => {
    switch (order) {
      case 'acquisition':
        return 'Acquisito';
      case 'estimate':
        return 'Supplemento installazione';
      case 'opened':
        return 'In lavorazione';
      case 'rejected':
        return 'Respinto';
      case 'opn-rejected':
        return 'Integrazione apertura';
      case 'inst-rejected':
        return 'Integrazione installazione';
      case 'confirmed':
        return 'Installazione presso cliente';
      case 'installed':
        return 'Installazione completata';
      case 'closed':
        return 'Chiuso';
      default:
        return 'Tutti';
    }
  };
  const renderClassStatus = (order: string) => {
    switch (order) {
      case 'acquisition':
        return 'bg-acquisition';
      case 'estimate':
        return 'bg-estimate';
      case 'opened':
        return 'bg-opened';
      case 'rejected':
        return 'bg-rejected';
      case 'opn-rejected':
        return 'bg-opnRejected';
      case 'inst-rejected':
        return 'bg-instRejected';
      case 'confirmed':
        return 'bg-confirmed';
      case 'installed':
        return 'bg-installed';
      case 'closed':
        return 'bg-closed';
      default:
        return 'badge-ghost';
    }
  };

  const ordersReverse = useMemo(() => orders && orders.map((order) => order) || [], [orders]);

  const filterOrders = (filter: OrderStatus) => {
    if (statusOrder !== filter) {
      dispatch(actions.setFilters({ status: filter as OrderStatus }));
      setStatusOrder(filter);
      dispatch(actions.resetSetSelectedOrder());
    }
  };

  const filterOrderCode = (filterCode: string | null) => {
    if (filterCode) {
      dispatch(actions.setFilters({ filter: filterCode }));
    }
  };

  const badgeOrders = (status: OrderStatus) => {
    if (status === 'opn-rejected' || status === 'inst-rejected' || status === 'confirmed') {
      renderClassStatus(status);
    } else {
      return false;
    }
    return true;
  };

  const pressInput = () => {
    setTimeout(() => document.getElementById('orderSearch')?.focus(), 50);
  };

  return (
    <div className="listWidth">
      <div className="max-w-md mb-8 lg:mb-0 shadow-md">
        <div className="text-center text-white py-4 text-xl bg-primary">
          <h1>
            Lista ultimi ordini
          </h1>
          <div
            className="mt-2 flex justify-center items-center cursor-pointer relative"
            onClick={() => setShowModal(!showModal)}
          >
            <Filter className="mr-2" />
            <div className={`badge ${renderClassStatus(statusOrder)} text-white`}>
              {getOrderStateName(statusOrder)}
            </div>
            {showModal
          && (
          <div className="w-full shadow-md absolute bg-bgModal top-8 z-10 text-white">
            <div
              className="flex justify-between p-4 hover:bg-primary-focus cursor-pointer"
              onClick={() => { setStatusOrder('Tutti'); dispatch(actions.setResetFilters()); dispatch(actions.resetSetSelectedOrder()); }}
            >
              <div
                className="badge badge-lg badge-ghost text-white"
              >
                Tutti
              </div>
            </div>
            {orderStatus?.map((e) => (
              <div
                onClick={() => { filterOrders(e as OrderStatus); onPagination(1); setPage(1); }}
                className="flex justify-between p-4 hover:bg-primary-focus cursor-pointer border-t-2 border-primary"
              >
                <div className={`badge badge-lg text-white border-0 ${renderClassStatus(e)}`}>
                  {getOrderStateName(e)}
                </div>
              </div>
            ))}
          </div>
          )}
          </div>
          { showSearchOrders
          && (
          <input
            type="text"
            id="orderSearch"
            value={OrdersInput}
            onChange={(e) => {
              if (e.target.value.length > 2) {
                filterOrderCode(e.target.value);
              } else {
                dispatch(actions.setResetFilters());
              }
            }}
            placeholder="Cerca ordine"
            className="text-primary font-semibold w-full pr-16 input input-primary input-bordered rounded-none -mb-4 mt-4"
          />
          ) }
        </div>
        <>
          {(ordersReverse.length > 0 && (
            <table className="table w-full z-0">
              <thead>
                <th className="rounded-l-none">Data</th>
                <th>Ora</th>
                <th className="rounded-r-none relative">
                  Ordine
                  <Search className="absolute right-2 top-4 cursor-pointer" onClick={() => { setShowSearchOrders(!showSearchOrders); pressInput(); }} />
                </th>
              </thead>
              <tbody className="text-sm">
                {ordersReverse
                  .map((order, i) => (
                    <tr
                      key={order.id}
                      className={
                                            orderActive === i && selectedOrder && pageOrderActive === page
                                              ? 'active text-primary cursor-pointer relative'
                                              : 'cursor-pointer relative'
                                        }
                      onClick={() => {
                        setOrderActive(i);
                        setPageOrderActive(page);
                        dispatch(
                          getOrder(order.id),
                        );
                        dispatch(getOrderDocuments(order.id));
                      }}
                    >
                      <td>
                        {moment(order.date).format('DD/MM/yy')}
                      </td>
                      <td>{moment(order.date).format('HH:mm')}</td>
                      <td>
                        {order.reference}
                      </td>
                      {badgeOrders(order.status) && statusOrder === 'Tutti'
                    && (
                    <div className="absolute flex h-3 w-3 right-1 top-1">
                      <span className={`animate-ping absolute inline-flex h-full w-full rounded-full ${renderClassStatus(order.status)} opacity-75`} />
                      <span className={`relative inline-flex rounded-full h-3 w-3 ${renderClassStatus(order.status)}`} />
                    </div>
                    ) }
                    </tr>
                  ))}
              </tbody>
            </table>
          )) || (
          <div className="flex justify-center py-3">
            <p>Nessun ordine presente</p>
          </div>
          )}
        </>
        <div className="flex justify-around py-4 border-t border-base-200">
          <Arrow className={selectedLastPage === page ? 'hover:scale-125 cursor-pointer transition-all duration-500 arrowDown-disabled' : 'hover:scale-125 cursor-pointer transition-all duration-500'} onClick={nextPageHandler} />
          <Arrow className={page === 1 ? 'rotate-180 hover:scale-125 cursor-pointer transition-all duration-500 arrowUp-disabled' : 'rotate-180 hover:scale-125 cursor-pointer transition-all duration-500'} onClick={prevPageHandler} />
        </div>
      </div>
    </div>
  );
};

export default OrderList;

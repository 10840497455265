/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-self-assign */
/* eslint-disable prefer-destructuring */
import React, {
  ChangeEvent,
  FunctionComponent,
  LabelHTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrencyNumber } from 'src/helpers';
import { selectToken } from 'src/features/Auth';
import styled from 'styled-components';
import {
  Order,
  OrderStatus,
  PdcDocuments,
  PhotoOldGenerator,
  selectDocuments,
  selectSelectedOrder,
} from '..';
import { ReactComponent as Check } from '../../../assets/Icon-check.svg';
import { ReactComponent as Upload } from '../../../assets/Icon-upload.svg';
import { ReactComponent as Pen } from '../../../assets/Icon-pen.svg';
import { ReactComponent as Eye } from '../../../assets/Icon-eye.svg';
import { actions } from '../../Common';
import {
  changeStateToInstalled, changeStateToEstimate, changeStateToOpened, getOrder,
} from '../services';
import ProductDetail from './ProductDetail';
import background from '../../../assets/background-green.png';
import ExtraInstallation from './ExtraInstallation';
import { actions as customersActions, selectBuilding, selectCustomer } from '../../Customers';
import RejectedModal from './rejectedModal';

export interface OrderDetailProps {
    order: Order
}

const OrderDetail: FunctionComponent<OrderDetailProps> = ({ order }) => {
  const [selectedPhotoOldGenerator, setSelectedPhotoOldGenerator] = useState<
        PhotoOldGenerator[]
    >();
  const [selectedPhotoOldGeneratorLabel, setSelectedPhotoOldGeneratorLabel] = useState<File>();
  const [selectedPhotoOldGeneratorPhoto, setSelectedPhotoOldGeneratorPhoto] = useState<File>();
  const [selectedPurchaseInvoices, setSelectedPurchaseInvoices] = useState<File>();
  const [selectedPropertyDeed, setSelectedPropertyDeed] = useState<File>();
  const [selectedAcceptedOffer, setSelectedAcceptedOffer] = useState<File>();
  const [selectedWireTransfer, setSelectedWireTransfer] = useState<File>();
  const [selectedHousingTitle, setSelectedHousingTitle] = useState<File>();
  const [selectedCadastralSurvey, setSelectedCadastralSurvey] = useState<File>();
  const [selectedIdentityCard, setSelectedIdentityCard] = useState<File>();
  const [selectedFiscalCodeCard, setSelectedFiscalCodeCard] = useState<File>();
  const [photoGeneratorToUpload, setPhotoGeneratorToUpload] = useState<number>(0);
  const [pdcToUpload, setPdcToUpload] = useState<number>(0);
  const [numberPhoto, setNumberPhoto] = useState<number>(0);
  const [numberLabel, setNumberLabel] = useState<number>(0);
  const [pdcPhoto, setPdcPhoto] = useState<number>(0);
  const [addCountPhoto, setAddCountPhoto] = useState<number>(0);
  const [addCountLabel, setAddCountLabel] = useState<number>(0);
  const [identityDocument, setIdentityDocument] = useState<number>(0);
  const [classStatus, setClassStatus] = useState<string>('');
  const [showEstimateModal, setShowEstimateModal] = useState<boolean>(false);
  const [showRejectedModal, setShowRejectedModal] = useState<boolean>(false);
  const [isNullValue, setIsNullValue] = useState<boolean>(true);
  const [isNullPdcValue, setIsNullPdcValue] = useState<boolean>(true);
  const [selectedTest, setSelectedTest] = useState<File>();
  const [selectedInterventionSheet, setSelectedInterventionSheet] = useState<File>();
  const [selectedPdc, setSelectedPdc] = useState<PdcDocuments[]>();
  const [identityCardExist, setIdentityCardExist] = useState<boolean>(false);
  const [fiscalCodeCardExist, setFiscalCodeCardExist] = useState<boolean>(false);
  const [propertyDeedExist, setPropertyDeedExist] = useState<boolean>(false);
  const [acceptedOfferExist, setAcceptedOfferExist] = useState<boolean>(false);
  const [buildingPermitExist, setBuildingPermitExist] = useState<boolean>(false);
  const [cadastralCertificateExist, setCadastralCertificateExist] = useState<boolean>(false);
  const [paymentExist, setPaymentExist] = useState<boolean>(false);
  const [identityDocumentExist, setIdentityDocumentExist] = useState<boolean>(false);
  const [testExist, setTestExist] = useState<boolean>(false);
  const [interventionSheetExist, setInterventionSheetExist] = useState<boolean>(false);
  const [invoiceExist, setInvoiceExist] = useState<boolean>(false);
  const [isSostituzione, setIsSostituzione] = useState<boolean>(false);

  const token = useSelector(selectToken);
  const selectedCustomer = useSelector(selectCustomer);
  const selectedBuilding = useSelector(selectBuilding);
  const orderDocuments = useSelector(selectDocuments);
  const selectedOrder = useSelector(selectSelectedOrder);

  const dispatch = useDispatch();
  // Hook
  const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
  };

  const selectedPreviousOrder = usePrevious(order?.reference);

  useEffect(() => {
    setIdentityCardExist(false);
    setFiscalCodeCardExist(false);
    setPropertyDeedExist(false);
    setAcceptedOfferExist(false);
    setBuildingPermitExist(false);
    setCadastralCertificateExist(false);
    setPaymentExist(false);
    setTestExist(false);
    setInterventionSheetExist(false);
    setInvoiceExist(false);
    if (orderDocuments) {
      const identityCard = orderDocuments
        ?.filter((document) => document.type === 'identity-card')
        .map((e) => e);
      if (identityCard.length > 0 && order.status === 'opn-rejected') {
        setIdentityCardExist(true);
      }
    }
    if (orderDocuments) {
      const fiscalCodeCard = orderDocuments
        ?.filter((document) => document.type === 'fiscalcode-card')
        .map((e) => e);
      if (fiscalCodeCard.length > 0 && order.status === 'opn-rejected') {
        setFiscalCodeCardExist(true);
      }
    }
    if (orderDocuments) {
      const propertyDeed = orderDocuments
        ?.filter((document) => document.type === 'property-deed')
        .map((e) => e);
      if (propertyDeed.length > 0 && order.status === 'opn-rejected') {
        setPropertyDeedExist(true);
      }
    }
    if (orderDocuments) {
      const acceptedOffer = orderDocuments
        ?.filter((document) => document.type === 'accepted-offer')
        .map((e) => e);
      if (acceptedOffer.length > 0 && order.status === 'opn-rejected') {
        setAcceptedOfferExist(true);
      }
    }
    if (orderDocuments) {
      const buildingPermit = orderDocuments
        ?.filter((document) => document.type === 'building-permit')
        .map((e) => e);
      if (buildingPermit.length > 0 && order.status === 'opn-rejected') {
        setBuildingPermitExist(true);
      }
    }
    if (orderDocuments) {
      const cadastralCertificate = orderDocuments
        ?.filter((document) => document.type === 'cadastral-certificate')
        .map((e) => e);
      if (cadastralCertificate.length > 0 && order.status === 'opn-rejected') {
        setCadastralCertificateExist(true);
      }
    }
    if (orderDocuments) {
      const payment = orderDocuments
        ?.filter((document) => document.type === 'payment')
        .map((e) => e);
      if (payment.length > 0 && order.status === 'opn-rejected') {
        setPaymentExist(true);
      }
    }
    if (orderDocuments) {
      const test = orderDocuments
        ?.filter((document) => document.type === 'test')
        .map((e) => e);
      if (test.length > 0 && order.status === 'inst-rejected') {
        setTestExist(true);
      }
    }
    if (orderDocuments) {
      const interventionSheet = orderDocuments
        ?.filter((document) => document.type === 'intervention-sheet')
        .map((e) => e);
      if (interventionSheet.length > 0 && order.status === 'inst-rejected') {
        setInterventionSheetExist(true);
      }
    }
    if (orderDocuments) {
      const invoice = orderDocuments
        ?.filter((document) => document.type === 'invoice')
        .map((e) => e);
      if (invoice.length > 0 && order.status === 'inst-rejected') {
        setInvoiceExist(true);
      }
    }
  }, [orderDocuments]);

  useEffect(() => {
    if (selectedPreviousOrder !== order?.reference) {
      dispatch(actions.setIsDifferentOrder(true));
      dispatch(actions.setAbleToSend(false));
      setSelectedIdentityCard(undefined);
      setSelectedFiscalCodeCard(undefined);
      setSelectedPropertyDeed(undefined);
      setSelectedAcceptedOffer(undefined);
      setSelectedPhotoOldGenerator([]);
      setSelectedPdc(undefined);
      setNumberPhoto(1);
      setAddCountLabel(0);
      setAddCountPhoto(0);
      setNumberLabel(1);
      setIdentityDocument(0);
      setSelectedPurchaseInvoices(undefined);
      setSelectedWireTransfer(undefined);
      setSelectedHousingTitle(undefined);
      setSelectedCadastralSurvey(undefined);
      setSelectedTest(undefined);
      setSelectedInterventionSheet(undefined);
      setSelectedPdc([]);
    } else {
      dispatch(actions.setIsDifferentOrder(false));
    }
    if (order) {
      const arrayOfId: number[] = [];
      const arrayOfIndex: number[] = [];
      const arrayOfUpload: PhotoOldGenerator[] = [];
      const { length } = order.products.filter(
        ({ bonusType }) => bonusType === 'ecobonus - sostituzione',
      );
      setPhotoGeneratorToUpload(length);

      if (length !== 0) {
        setNumberPhoto(length);
        setNumberLabel(length);
      } else {
        setPhotoGeneratorToUpload(0);
      }
      // eslint-disable-next-line array-callback-return
      order.products.map((e, i) => {
        if (e.bonusType === 'ecobonus - sostituzione') {
          arrayOfIndex.push(i);
        }
      });

      order.products
        .filter(({ bonusType }) => bonusType === 'ecobonus - sostituzione')
        .map((e) => arrayOfId.push(e.id));
      // eslint-disable-next-line max-len
      arrayOfId.map((e, i) => arrayOfUpload.push({
        id: e,
        photoLabelGenerator: null,
        photoOldGenerator: null,
        index: arrayOfIndex[i],
      }));
      setSelectedPhotoOldGenerator(arrayOfUpload);
    }
    if (order.status === 'confirmed') {
      const arrayOfId: number[] = [];
      const arrayOfIndex: number[] = [];
      const arrayOfUpload: PdcDocuments[] = [];
      const { length } = order.products.filter(
        ({ bonusType }) => bonusType === 'ecobonus - sostituzione',
      );
      setPdcToUpload(length);

      if (length !== 0) {
        setPdcPhoto(length);
      } else {
        setPdcToUpload(0);
      }
      // eslint-disable-next-line array-callback-return
      order.products.map((e, i) => {
        if (e.bonusType === 'ecobonus - sostituzione') {
          arrayOfIndex.push(i);
        }
      });

      order.products
        .filter(({ bonusType }) => bonusType === 'ecobonus - sostituzione')
        .map((e) => arrayOfId.push(e.id));
      // eslint-disable-next-line max-len
      arrayOfId.map((e, i) => arrayOfUpload.push({
        id: e,
        index: arrayOfIndex[i],
        pdc: null,
      }));
      setSelectedPdc(arrayOfUpload);
    }
  }, [order, selectedPreviousOrder, dispatch]);

  const ProgressiveLabel = styled.label<
        LabelHTMLAttributes<HTMLLabelElement> & { totalCountLabel: number; countLabel: number }
    >`
        background-image: url(${(order.status === 'acquisition' || order.status === 'estimate') ? background : ''});
        background-size: ${(props) => (props.totalCountLabel !== 0
                    && Number((100 / props.totalCountLabel).toFixed(1)) * props.countLabel)
                || 0}%
            104%;
        background-repeat: no-repeat;
        min-width: 265px;
    `;

  const isNull = () => {
    let count = 0;
    selectedPhotoOldGenerator?.forEach((e) => {
      if (e.photoLabelGenerator !== null && e.photoOldGenerator !== null) {
        count += 1;
        if (count === selectedPhotoOldGenerator.length) {
          setIsNullValue(false);
        }
      } else {
        setIsNullValue(true);
      }
    });
  };

  const isNullPdc = () => {
    let count = 0;
    selectedPdc?.forEach((e) => {
      if (e.pdc !== null) {
        count += 1;
        if (count === selectedPdc.length) {
          setIsNullPdcValue(false);
        }
      } else {
        setIsNullPdcValue(true);
      }
    });
  };

  useEffect(() => {
    isNull();
  }, [isNull, selectedPhotoOldGenerator]);

  useEffect(() => {
    isNullPdc();
  }, [isNull, selectedPdc]);

  useEffect(() => {
    let counter = 0;
    order.products.map((e) => {
      if (e.bonusType === 'ecobonus - sostituzione') {
        counter += 1;
      }
      if (counter > 0) {
        setIsSostituzione(true);
      } else {
        setIsSostituzione(false);
      }
    });
  }, [order]);

  useEffect(() => {
    if (order.status === 'acquisition' || order.status === 'estimate') {
      if (isSostituzione) {
        if (
          selectedIdentityCard
                  && selectedFiscalCodeCard
                  && selectedPropertyDeed
                  && selectedAcceptedOffer
                  && !isNullValue
                  && selectedHousingTitle
                  && selectedCadastralSurvey
                  && selectedBuilding?.id
                  && selectedCustomer?.id
        ) {
          dispatch(actions.setAbleToSend(true));
        } else {
          dispatch(actions.setAbleToSend(false));
        }
      } else if (
        selectedIdentityCard
                  && selectedFiscalCodeCard
                  && selectedPropertyDeed
                  && selectedAcceptedOffer
                  && selectedHousingTitle
                  && selectedCadastralSurvey
                  && selectedBuilding?.id
                  && selectedCustomer?.id
      ) {
        dispatch(actions.setAbleToSend(true));
      } else {
        dispatch(actions.setAbleToSend(false));
      }
    }
    if (order.status === 'confirmed') {
      if (isSostituzione) {
        if (
          selectedTest
                  && selectedInterventionSheet
                  && selectedPurchaseInvoices
                  && !isNullPdcValue
        ) {
          dispatch(actions.setAbleToSend(true));
        } else {
          dispatch(actions.setAbleToSend(false));
        }
      } else if (
        selectedTest
                  && selectedInterventionSheet
                  && selectedPurchaseInvoices
      ) {
        dispatch(actions.setAbleToSend(true));
      } else {
        dispatch(actions.setAbleToSend(false));
      }
    }
    if (order.status === 'opn-rejected') {
      if ((!buildingPermitExist && !selectedHousingTitle)
      || (!cadastralCertificateExist && !selectedCadastralSurvey)
      || (!identityCardExist && !selectedIdentityCard)
      || (!fiscalCodeCardExist && !selectedFiscalCodeCard)
      || (!propertyDeedExist && !selectedPropertyDeed)
      || (!acceptedOfferExist && !selectedAcceptedOffer)) {
        dispatch(actions.setAbleToSend(false));
      } else {
        dispatch(actions.setAbleToSend(true));
      }
    }
    if (order.status === 'inst-rejected') {
      if ((!testExist && !selectedTest)
      || (!interventionSheetExist && !selectedInterventionSheet)
      || (!invoiceExist && !selectedPurchaseInvoices)) {
        dispatch(actions.setAbleToSend(false));
      } else {
        dispatch(actions.setAbleToSend(true));
      }
    }
  }, [
    selectedCustomer,
    selectedBuilding,
    isNullValue,
    isNullPdcValue,
    selectedPhotoOldGenerator,
    selectedWireTransfer,
    selectedIdentityCard,
    selectedFiscalCodeCard,
    selectedPropertyDeed,
    selectedAcceptedOffer,
    selectedHousingTitle,
    selectedCadastralSurvey,
    identityDocument,
    dispatch,
    addCountLabel,
    photoGeneratorToUpload,
    addCountPhoto,
    selectedTest,
    selectedInterventionSheet,
    selectedPurchaseInvoices,
    selectedPdc,
    order.status,
    isSostituzione,
    buildingPermitExist,
    cadastralCertificateExist,
    paymentExist,
    identityDocumentExist,
    testExist,
    interventionSheetExist,
    invoiceExist,
    identityCardExist,
    fiscalCodeCardExist,
    propertyDeedExist,
    acceptedOfferExist,
  ]);

  /*   useEffect(() => {
    if (selectedPhotoOldGeneratorPhoto) {
      const array: PhotoOldGenerator[] = [];
      selectedPhotoOldGenerator?.map((e) => array.push({
        id: e.id,
        photoOldGenerator: selectedPhotoOldGeneratorPhoto,
        photoLabelGenerator: e.photoLabelGenerator,
        index: e.index,
      }));
      setSelectedPhotoOldGenerator(array);
      setNumberPhoto(0);
    }
  }, [selectedPhotoOldGenerator, selectedPhotoOldGeneratorPhoto]);

  useEffect(() => {
    if (selectedPhotoOldGeneratorLabel) {
      const array: PhotoOldGenerator[] = [];
      selectedPhotoOldGenerator?.map((e) => array.push({
        id: e.id,
        photoOldGenerator: e.photoOldGenerator,
        photoLabelGenerator: selectedPhotoOldGeneratorLabel,
        index: e.index,
      }));
      setSelectedPhotoOldGenerator(array);
      setNumberLabel(0);
    }
  }, [selectedPhotoOldGenerator, selectedPhotoOldGeneratorLabel]); */

  useEffect(() => {
    if (order && order.building) {
      dispatch(customersActions.setBuilding(order.building));
      dispatch(customersActions.setCustomer(order.building.customer));
    }
  }, []);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    if (order.status === 'opn-rejected') {
      dispatch(
        changeStateToOpened({
          id: order.id,
          identityCard: selectedIdentityCard,
          fiscalCodeCard: selectedFiscalCodeCard,
          propertyDeed: selectedPropertyDeed,
          acceptedOffer: selectedAcceptedOffer,
          documents: selectedPhotoOldGenerator,
          housing: selectedHousingTitle,
          cadastral: selectedCadastralSurvey,
          buildingId: selectedBuilding?.id,
        }),
      );
    } else if (
      order
            && token
            && selectedIdentityCard
            && selectedFiscalCodeCard
            && selectedPropertyDeed
            && selectedAcceptedOffer
            && selectedPhotoOldGenerator
            && selectedHousingTitle
            && selectedCadastralSurvey
            && selectedBuilding
            && selectedCustomer
    ) {
      dispatch(
        changeStateToOpened({
          id: order.id,
          identityCard: selectedIdentityCard,
          fiscalCodeCard: selectedFiscalCodeCard,
          propertyDeed: selectedPropertyDeed,
          acceptedOffer: selectedAcceptedOffer,
          documents: selectedPhotoOldGenerator,
          housing: selectedHousingTitle,
          cadastral: selectedCadastralSurvey,
          buildingId: selectedBuilding.id,
        }),
      );
    }
  };

  const onSubmitInstalledForm = (e: any) => {
    e.preventDefault();
    if (order.status === 'inst-rejected') {
      dispatch(
        changeStateToInstalled({
          id: order.id,
          pdc: selectedPdc,
          test: selectedTest,
          interventionSheet: selectedInterventionSheet,
          invoice: selectedPurchaseInvoices,
        }),
      );
    } else if (
      order
            && selectedTest
            && selectedInterventionSheet
            && selectedPurchaseInvoices
            && selectedPdc
    ) {
      dispatch(
        changeStateToInstalled({
          id: order.id,
          pdc: selectedPdc,
          test: selectedTest,
          interventionSheet: selectedInterventionSheet,
          invoice: selectedPurchaseInvoices,
        }),
      );
    }
  };

  /*   const onInputIdentityCard = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const array: IdentityFile[] = [
        {
          identityFront: null,
          identityBack: null,
          fiscalCodeFront: null,
          fiscalCodeBack: null,
        },
      ];
      selectedIdentityCard?.map((el) => array.push(el));
      if (identityDocument === 0) {
        array[0].identityFront = e.target.files[0];
        setIdentityCardFront(e.target.files[0]);
        setIdentityDocument(identityDocument + 1);
      } else if (identityDocument === 1) {
        setIdentityCardRetro(e.target.files[0]);
        array[0].identityFront = identityCardFront;
        array[0].identityBack = e.target.files[0];
        setIdentityDocument(identityDocument + 1);
      } else if (identityDocument === 2) {
        setFiscalCardFront(e.target.files[0]);
        array[0].identityFront = identityCardFront;
        array[0].identityBack = identityCardRetro;
        array[0].fiscalCodeFront = e.target.files[0];
        setIdentityDocument(identityDocument + 1);
      } else if (identityDocument === 3) {
        setFiscalCardRetro(e.target.files[0]);
        array[0].identityFront = identityCardFront;
        array[0].identityBack = identityCardRetro;
        array[0].fiscalCodeFront = fiscalCardFront;
        array[0].fiscalCodeBack = e.target.files[0];
        setIdentityDocument(identityDocument + 1);
      }
      setSelectedIdentityCard(array);
    }
  }; */

  // const insertInArray = (e: PhotoOldGenerator) => {
  //   const array: PhotoOldGenerator[] | null | undefined = selectedPhotoOldGenerator;

  //   const productsFilled: Set<PhotoOldGenerator> = new Set();

  //   if (array) {
  //     for (const prodData of array) {
  //       if (e.id === prodData.id && e.index === prodData.index) {
  //         productsFilled.add(e);
  //       } else {
  //         productsFilled.add(prodData);
  //       }
  //     }
  //   }

  //   setSelectedPhotoOldGenerator(Array.from(productsFilled));
  // };

  const insertInArray = (e: PhotoOldGenerator) => {
    const array: PhotoOldGenerator[] | null | undefined = selectedPhotoOldGenerator;

    const productsFilled: PhotoOldGenerator[] = [];

    if (array) {
      for (const prodData of array) {
        if (e.index === prodData.index) {
          productsFilled.push(e);
        } else {
          productsFilled.push(prodData);
        }
      }
    }

    setSelectedPhotoOldGenerator(productsFilled);
  };

  const insertPdcInArray = (e: PdcDocuments) => {
    const array: PdcDocuments[] | null | undefined = selectedPdc;

    const productsFilled: Set<PdcDocuments> = new Set();

    if (array) {
      for (const prodData of array) {
        if (e.index === prodData.index) {
          productsFilled.add(e);
        } else {
          productsFilled.add(prodData);
        }
      }
    }

    setSelectedPdc(Array.from(productsFilled));
  };

  const onInputIdentityCard = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedIdentityCard(e.target.files[0]);
    }
  };

  const onInputFiscalCodeCard = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedFiscalCodeCard(e.target.files[0]);
    }
  };

  const onInputPropertyDeed = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedPropertyDeed(e.target.files[0]);
    }
  };

  const onInputAcceptedOffer = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedAcceptedOffer(e.target.files[0]);
    }
  };

  const onInputWireTransfer = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedWireTransfer(e.target.files[0]);
    }
  };

  const onInputHousingTitle = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedHousingTitle(e.target.files[0]);
    }
  };

  const onInputCadastralSurvey = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedCadastralSurvey(e.target.files[0]);
    }
  };

  const onInputTest = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedTest(e.target.files[0]);
    }
  };
  const onInputInterventionSheet = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedInterventionSheet(e.target.files[0]);
    }
  };
  const onInputPurchaseInvoices = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSelectedPurchaseInvoices(e.target.files[0]);
    }
  };

  /* const RenderIdentityDocument = () => {
    if (order.status !== 'opened' && order.status !== 'closed' && order.status !== 'rejected') {
      if (identityDocument === 0) {
        return "Carta d'identità fronte";
      }
      if (identityDocument === 1) {
        return "Carta d'identità retro";
      }
      if (identityDocument === 2) {
        return 'Codice fiscale fronte';
      }
      if (identityDocument === 3) {
        return 'Codice fiscale retro';
      }
    }
    return "Documenti d'identità";
  }; */

  const renderClassStatus = () => {
    switch (order.status) {
      case OrderStatus.Acquisition:
        return 'bg-acquisition';
      case OrderStatus.Estimate:
        return 'bg-estimate';
      case OrderStatus.Opened:
        return 'bg-opened';
      case OrderStatus.Rejected:
        return 'bg-rejected';
      case OrderStatus.OpnRejected:
        return 'bg-opnRejected';
      case OrderStatus.InstRejected:
        return 'bg-instRejected';
      case OrderStatus.Confirmed:
        return 'bg-confirmed';
      case OrderStatus.Installed:
        return 'bg-installed';
      case OrderStatus.Closed:
        return 'bg-closed';
      default:
        return '';
    }
  };

  const getOrderStateName = () => {
    switch (order.status) {
      case OrderStatus.Acquisition:
        return 'Acquisito';
      case OrderStatus.Estimate:
        return 'Supplemento installazione';
      case OrderStatus.Opened:
        return 'In lavorazione';
      case OrderStatus.Rejected:
        return 'Respinto';
      case OrderStatus.OpnRejected:
        return 'Integrazione apertura';
      case OrderStatus.InstRejected:
        return 'Integrazione installazione';
      case OrderStatus.Confirmed:
        return 'Installazione presso cliente';
      case OrderStatus.Installed:
        return 'Installazione completata';
      case OrderStatus.Closed:
        return 'Chiuso';
      default:
        return '';
    }
  };

  useEffect(() => {
    setClassStatus(renderClassStatus());
  }, [order.status]);

  useEffect(() => {
    if (
      order.status === 'rejected'
            || order.status === 'opn-rejected'
            || order.status === 'inst-rejected'
    ) {
      setShowRejectedModal(true);
    }
  }, [order]);

  const onViewIdentityCardFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'identity-card') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewFiscalCodeCardFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'fiscalcode-card') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewPropertyDeedFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'property-deed') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewAcceptedOfferFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'accepted-offer') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewPaymentFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'payment') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewCadastralFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'cadastral-certificate') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewBuildingPermitFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'building-permit') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewTestFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'test') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewInterventionSheetFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'intervention-sheet') {
        return window.open(document.url);
      }
      return null;
    });
  };
  const onViewPurchaseInvoicesFile = () => {
    orderDocuments?.forEach((document) => {
      if (document.url && document.type === 'invoice') {
        return window.open(document.url);
      }
      return null;
    });
  };

  const initialExtraAmount = useMemo(
    () => order.products.map((prod) => prod.extraAmount).reduce((prev, curr) => prev + curr),
    [order],
  );

  return (
    <div className="w-full ml-0 shadow-md min-h-orderDetail lg:ml-12">
      <div className="text-white py-8 bg-primary flex justify-center items-center relative">
        <h1 className="text-xl text-center">
          Dettaglio ordine
          {' '}
          {order?.reference}
        </h1>
        <div className={`absolute sm:right-4 top-2 badge ${classStatus}`}>
          {getOrderStateName()}
        </div>
      </div>
      <div className="py-8 px-4 md:px-8 w-full h-full">
        <div className="flex h-1/2 flex-wrap justify-center">
          {order?.products?.map((product, i) => (
            <ProductDetail
              product={product}
              id={product.id}
              index={i}
              onDocumentsComplete={insertInArray}
              onPdcDocumentsComplete={insertPdcInArray}
            />
          ))}
        </div>
        <div className="sm:flex-row flex flex-col items-center justify-center my-8">
          {(
            (order.status === 'acquisition'
                        || order.status === 'estimate')
                        || ((order.totalExtraAmount - initialExtraAmount) > 0)
          ) && (
            <h1 className="mr-2 flex items-center text-center">
              {`Supplemento installazione ${
                (order.extraAmountMotivation !== 'standard'
                                    && `${formatCurrencyNumber(
                                      order.totalExtraAmount - initialExtraAmount,
                                    )}€`)
                                || ''
              }`}
            </h1>
          )}
          {order.status === 'acquisition' && (
            <>
              <label
                htmlFor="estimate-modal"
                className="mr-8 btn bg-white border-2 border-primary btn-sm modal-button modal-open"
                onClick={() => setShowEstimateModal(true)}
              >
                <Pen className="mx-2" />
              </label>
              <input
                type="checkbox"
                id="estimate-modal"
                className="modal-toggle"
                checked={showEstimateModal}
              />
              <ExtraInstallation
                                // eslint-disable-next-line max-len
                onSubmit={(values) => dispatch(changeStateToEstimate({ id: order.id, ...values }))}
                onClose={() => setShowEstimateModal(false)}
                estimate={{
                  initialExtraAmount,
                  extraAmount: order.totalExtraAmount,
                  extraAmountMotivation: order.extraAmountMotivation,
                }}
              />
            </>
          )}
          {/* <input
              className="input input-ghost input-primary font-medium"
              type="number"
              min={0}
              max={999}
              defaultValue={serviceInstallation}
              onChange={(e) => setServiceInstallation(Number(e.currentTarget.value))}
            /> */}
          {(order.status === 'rejected'
                        || order.status === 'opn-rejected'
                        || order.status === 'inst-rejected')
                        && showRejectedModal && (
                        <RejectedModal onClose={() => setShowRejectedModal(false)} />
          )}
          <h1 className="text-sm ml-8 text-center my-8 sm:my-0">
            {' '}
            {`Totale senza sconto ecobonus ${formatCurrencyNumber(
              order?.totalPrice + order?.totalExtraAmount,
            )}€`}
          </h1>
          <h1 className="ml-8 text-2xl text-center">
            Totale con sconto ecobonus
            {' '}
            <span className="font-bold">
              {formatCurrencyNumber(order?.totalDiscount + order?.totalExtraAmount)}
              €
            </span>
          </h1>
        </div>
        {(order.status !== 'installed'
                    && order.status !== 'confirmed'
                    && order.status !== 'inst-rejected') && !(!selectedCustomer && order.status === 'rejected') && (
                    <form
                      className="flex justify-center flex-wrap"
                      onSubmit={(e) => onSubmitForm(e)}
                      id="formUploadFiles"
                    >
                      {!identityCardExist && (
                      <label
                        data-tip="Copia del documento di indentità fronte retro"
                        className={
                                        selectedIdentityCard && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputIdentityCard"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputIdentityCard(e)
                                              : undefined
                                        }
                          id="inputIdentityCard"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewIdentityCardFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedIdentityCard ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Documento di identità
                      </label>
                      )}
                      {!fiscalCodeCardExist && (
                      <label
                        data-tip="Copia del codice fiscale fronte retro"
                        className={
                                        selectedFiscalCodeCard && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputFiscalCodeCard"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputFiscalCodeCard(e)
                                              : undefined
                                        }
                          id="inputFiscalCodeCard"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewFiscalCodeCardFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedFiscalCodeCard ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Codice fiscale
                      </label>
                      )}
                      {!buildingPermitExist && (
                      <label
                        data-tip="Copia del titolo abilitativo"
                        className={
                                        selectedHousingTitle && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputHousingTitle"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputHousingTitle(e)
                                              : undefined
                                        }
                          id="inputHousingTitle"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewBuildingPermitFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedHousingTitle ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Titolo abilitativo
                      </label>
                      )}
                      {!cadastralCertificateExist && (
                      <label
                        data-tip="Copia della visura catastale"
                        className={
                                        selectedCadastralSurvey && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputCadastralSurvey"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputCadastralSurvey(e)
                                              : undefined
                                        }
                          id="inputCadastralSurvey"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewCadastralFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedCadastralSurvey ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Visura catastale
                      </label>
                      )}
                      {!propertyDeedExist && (
                      <label
                        data-tip="Titolo di possesso"
                        className={
                                        selectedPropertyDeed && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputPropertyDeed"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputPropertyDeed(e)
                                              : undefined
                                        }
                          id="inputPropertyDeed"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewPropertyDeedFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedPropertyDeed ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Titolo di possesso
                      </label>
                      )}
                      {!acceptedOfferExist && (
                      <label
                        data-tip="Preventivo accettato e firmato"
                        className={
                                        selectedAcceptedOffer && order.status !== 'opened'
                                          ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputAcceptedOffer"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputAcceptedOffer(e)
                                              : undefined
                                        }
                          id="inputAcceptedOffer"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewAcceptedOfferFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedAcceptedOffer ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Preventivo firmato
                      </label>
                      )}
                      {/* {!paymentExist && (
                      <label
                        data-tip="bonifico parlante per detrazione fiscale"
                        className={
                                        selectedWireTransfer && order.status !== 'opened'
                                          ? 'btn bg-secondary transition duration-500 ease-in-out text-white border-secondary border-2 m-4 w-button'
                                          : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                                    }
                        htmlFor="inputWireTransfer"
                      >
                        <input
                          type={
                                            order.status !== 'opened' && order.status !== 'rejected' && order.status !== 'closed'
                                              ? 'file'
                                              : 'button'
                                        }
                          onChange={
                                            order.status !== 'opened'
                                              ? (e) => onInputWireTransfer(e)
                                              : undefined
                                        }
                          id="inputWireTransfer"
                          onClick={
                                            order.status === 'opened' || order.status === 'rejected' || order.status === 'closed'
                                              ? onViewPaymentFile
                                              : undefined
                                        }
                          formTarget="_blank"
                        />
                        {order.status === 'opened' || order.status === 'rejected' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedWireTransfer ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Bonifico
                      </label>
                      )} */}
                      <button type="submit" id="formUploadFilesButtom" hidden>
                        Invia
                      </button>
                    </form>
        )}
        {(order.status === 'installed'
                    || order.status === 'confirmed'
                    || order.status === 'inst-rejected'
                    || order.status === 'closed') && (
                    <form
                      className="flex justify-center flex-wrap"
                      onSubmit={(e) => onSubmitInstalledForm(e)}
                      id="formUploadFiles"
                    >
                      {!testExist
                      && (
                      <label
                        data-tip="Copia del certificato di collaudo"
                        className={
                                selectedTest && order.status !== 'installed'
                                  ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                  : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                            }
                        htmlFor="inputTest"
                      >
                        <input
                          type={order.status !== 'installed' && order.status !== 'closed' ? 'file' : 'button'}
                          onChange={
                                    order.status !== 'installed' ? (e) => onInputTest(e) : undefined
                                }
                          id="inputTest"
                          onClick={order.status === 'installed' || order.status === 'closed' ? onViewTestFile : undefined}
                          formTarget="_blank"
                        />
                        {order.status === 'installed' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedTest ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Certificato collaudo
                      </label>
                      )}
                      {!interventionSheetExist
                      && (
                      <label
                        data-tip="Scheda di intervento"
                        className={
                                selectedInterventionSheet && order.status !== 'installed'
                                  ? 'btn transition duration-500 ease-in-out bg-secondary text-white border-secondary border-2 m-4 w-button'
                                  : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                            }
                        htmlFor="inputInterventionSheet"
                      >
                        <input
                          type={order.status !== 'installed' && order.status !== 'closed' ? 'file' : 'button'}
                          onChange={
                                    order.status !== 'installed'
                                      ? (e) => onInputInterventionSheet(e)
                                      : undefined
                                }
                          id="inputInterventionSheet"
                          onClick={
                                    order.status === 'installed' || order.status === 'closed'
                                      ? onViewInterventionSheetFile
                                      : undefined
                                }
                          formTarget="_blank"
                        />
                        {order.status === 'installed' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedInterventionSheet ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Scheda intervento
                      </label>
                      )}
                      {!invoiceExist
                      && (
                      <label
                        data-tip="fattura degli acquisti effettuati"
                        className={
                                selectedPurchaseInvoices && order.status !== 'installed'
                                  ? 'btn bg-secondary transition duration-500 ease-in-out text-white border-secondary border-2 m-4 w-button'
                                  : ' btn bg-white text-primary border-primary border-2 m-4 tooltip tooltip-primary flex w-button'
                            }
                        htmlFor="inputPurchaseInvoices"
                      >
                        <input
                          type={order.status !== 'installed' && order.status !== 'closed' ? 'file' : 'button'}
                          onChange={
                                    order.status !== 'installed'
                                      ? (e) => onInputPurchaseInvoices(e)
                                      : undefined
                                }
                          id="inputPurchaseInvoices"
                          onClick={
                                    order.status === 'installed' || order.status === 'closed'
                                      ? onViewPurchaseInvoicesFile
                                      : undefined
                                }
                          formTarget="_blank"
                        />
                        {order.status === 'installed' || order.status === 'closed' ? (
                          <Eye className="mr-4" />
                        ) : selectedPurchaseInvoices ? (
                          <Check className="mr-4" />
                        ) : (
                          <Upload className="mr-4" />
                        )}
                        {' '}
                        Fattura acquisti
                      </label>
                      )}
                      <button type="submit" id="formUploadFilesButtom" hidden>
                        Invia
                      </button>
                    </form>
        )}
      </div>
    </div>
  );
};
export default OrderDetail;

import { createSelector, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { AuthState } from '.';
import { RootState } from '../../store';
import { login, logout } from './services';

const initialState: AuthState = {
  isLogged: false,
  loading: false,
  token: null,
  error: null,
};

export const selectIsLogged = createSelector(
  (state: RootState) => state.auth.isLogged,
  (isLogged) => isLogged,
);

export const selectToken = createSelector(
  (state: RootState) => state.auth.token,
  (token) => token,
);

export const selectError = createSelector(
  (state: RootState) => state.auth.error,
  (error) => error,
);

const { actions, name, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLogOut: (state: AuthState) => {
      state.isLogged = false;
      state.token = null;
    },
    setResetError: (state: AuthState) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      if (payload) {
        state.isLogged = true;
        state.token = payload.data.token;
      }

      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      if (action.error) {
        state.error = action.error as AxiosError;
      }
      state.loading = false;
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isLogged = false;
      state.token = null;
      state.loading = false;
    });
    builder.addCase(logout.rejected, (state, action) => {
      if (action.error) {
        state.error = action.error as AxiosError;
      }
      state.loading = false;
    });
  },
});

export { reducer, actions, name };

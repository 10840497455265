/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FunctionComponent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { selectError, actions as authActions } from '..';
import { ReactComponent as IconPassword } from '../../../assets/Icon-material-lock.svg';
import { ReactComponent as IconPerson } from '../../../assets/Icon-material-person.svg';

export interface LoginFormData {
    email: string
    password: string
}

export interface LoginFormProps {
    onSubmit: (values: LoginFormData) => void
}

const schema = yup.object().shape({
  email: yup.string().email('Formato email non valido').required('Email richiesta'),
  password: yup.string().min(8, 'Deve contenere minimo 8 caratteri').max(32, 'Non deve superare 32 caratteri').required('Password richiesta'),
});

const LoginForm: FunctionComponent<LoginFormProps> = ({

  onSubmit = () => {},
}) => {
  const {
    register, handleSubmit, formState: { errors },
  } = useForm<LoginFormData>({
    resolver: yupResolver(schema),
  });

  const isError = useSelector(selectError);
  const dispatch = useDispatch();

  return (
    <div>
      <form className="form-control " onSubmit={handleSubmit(onSubmit)}>
        <div className="relative">
          <input
            onClick={() => dispatch(authActions.setResetError())}
            type="text"
            placeholder="Email"
            className={
                              !errors.email
                                ? 'input input-primary input-bordered w-full rounded-full py-3 px-6 bg-black bg-opacity-40 border-primary-focus pl-16'
                                : 'input input-primary input-bordered w-full rounded-full py-3 px-6 bg-black bg-opacity-40 border-red-500 border-2 focus:ring-0 pl-16'
                          }
            {...register('email', {
              required: true,
            })}
          />

          <IconPerson className="absolute top-2.5 left-5 w-4" />

          {errors.email && (
            <span className="absolute top-12 left-5 mt-1 text-sm text-red-500">
              {errors.email.message}
            </span>
          )}
          {isError?.message.includes('400') && (
            <span className="absolute top-12 left-5 mt-1 text-sm text-red-500">
              Email non corretta
            </span>
          )}
        </div>
        <div className="relative">
          <input
            onClick={() => dispatch(authActions.setResetError())}
            type="password"
            placeholder="Password"
            className={
                            !errors.password
                              ? 'input input-primary input-bordered w-full my-10 rounded-full py-3 px-6 bg-black bg-opacity-40 border-primary-focus pl-16'
                              : 'input input-primary input-bordered w-full my-10 rounded-full py-3 px-6 bg-black bg-opacity-40 border-red-500 border-2 focus:ring-0 pl-16'
                        }
            {...register('password', { required: true })}
          />

          <IconPassword className="absolute top-12 left-5 w-3.5" />
          {errors.password && (
            <span className="absolute top-24 left-5 text-sm text-red-500">
              {errors.password.message}
            </span>
          )}
          {isError?.message.includes('401') && (
            <span className="absolute top-24 left-5 text-sm text-red-500">
              Password errata
            </span>
          )}
        </div>
        <button
          type="submit"
          className="btn btn-secondary rounded-full mt-4 py-3 px-6 w-full font-bold border-0"
        >
          Accedi
        </button>
      </form>
    </div>
  );
};

export default LoginForm;

import { SerializedError } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { PaginationOutput } from '../Common';
import { Building, Customer } from '../Customers';

export interface OrdersState {
    orders: PaginationOutput<Order> | null
    loading: boolean
    error: AxiosError | SerializedError | null
    selected: Order | null
    documents: OrderDocument[] | null
    isSended: boolean
    estimate: Estimate | null
    serviceDocumentState: 'pending' | 'rejected' | 'fullfilled'
    totalStatusOrders: boolean
    printOffer: number | null
    filters: OrderFilters | null
    lastPage: number | null
    filterActive: boolean
}

export interface OrderFilters {
    status?: OrderStatus
    filter?: string
}

export enum Currency {
    Euro = 'euro',
}

export enum OrderStatus {
    Acquisition = 'acquisition',
    Estimate = 'estimate',
    Opened = 'opened',
    Rejected = 'rejected',
    OpnRejected = 'opn-rejected',
    InstRejected = 'inst-rejected',
    Confirmed = 'confirmed',
    Installed = 'installed',
    Closed = 'closed',
}

export type CalcType = '%' | '-%' | 'value' | '-value'

export type BonusName = 'bonuscasa - nuovo' | 'ecobonus - sostituzione'

export interface BonusValue {
    type: CalcType
    quantity: number
}

export interface Bonus {
    name: BonusName
    value: BonusValue
}

export const BonusCollection: Record<BonusName, Bonus> = {
  'bonuscasa - nuovo': {
    name: 'bonuscasa - nuovo',
    value: { type: '-%', quantity: 50 },
  },
  'ecobonus - sostituzione': {
    name: 'ecobonus - sostituzione',
    value: { type: '-%', quantity: 65 },
  },
} as const;

export interface Product {
    id: number;
    reference: string;
    quantity: number;
    name: string;
    price: number;
    priceDiscount: number;
    priceWithExtraAmount: number;
    discountWithExtraAmount: number;
    taxAmount: number;
    taxAmountType: CalcType;
    extraAmount: number;
    extraAmountType: CalcType;
    bonusDiscount: number;
    bonusCalcType: CalcType;
    bonusType: BonusName;
  }

export enum CustomerDocumentType {
    IdentityCard = 'identity-card',
    FiscalCodeCard = 'fiscalcode-card',
    PropertyDeed = 'property-deed',
    AcceptedOffer = 'accepted-offer',
    CadastralCertificate = 'cadastral-certificate',
    BuildingPermit = 'building-permit',
    OldProd = 'old-prod',
    OldProdLabel = 'old-prod-label',
    Payment = 'payment',
}

export enum InstallerDocumentType {
    Pdc = 'pdc',
    Test = 'test',
    InterventionSheet = 'intervention-sheet',
    Invoice = 'invoice',
}

export interface OrderDocument {
    productId?: number;
    type: CustomerDocumentType | InstallerDocumentType;
    url: null | string;
}

export type StatusHistory = [Date, OrderStatus, string][];

export interface Order {
    id: number
    reference: string
    date: string
    status: OrderStatus
    statusHistory: StatusHistory
    customer: Customer
    building: Building
    totalPrice: number
    totalDiscount: number
    totalExtraAmount: number
    extraAmountMotivation: string
    rejectMotivation: string
    currency: 'euro'
    products: Product[]
}

export interface StateChangeToOpened {
    id: number
    identityCard?: File
    fiscalCodeCard?: File
    propertyDeed?: File
    acceptedOffer?: File
    documents?: PhotoOldGenerator[]
    housing?: File
    cadastral?: File
    buildingId?: number
}

export interface StateChangeToInstalled {
    id: number
    pdc?: PdcDocuments[]
    test?: File
    interventionSheet?: File
    invoice?: File
}

/* export interface IdentityFile {
    identityFront?: File | null
    identityBack?: File | null
    fiscalCodeFront?: File | null
    fiscalCodeBack?: File | null
} */

export interface PhotoOldGenerator {
    photoOldGenerator?: File | null
    photoLabelGenerator?: File | null
    id?: number
    index?: number
}
export interface MissingDocuments {
    type:string
}
export interface PdcDocuments {
    pdc?: File | null
    id?: number
    index?: number
}

export interface Estimate {
    id?: number
    initialExtraAmount?: number
    extraAmount: number
    extraAmountMotivation: string
}

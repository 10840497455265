import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import DatePicker from 'react-date-picker';
import React, {
  FunctionComponent, useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Plus } from '../../../assets/Icon-plus.svg';
import { ReactComponent as Close } from '../../../assets/Icon-close.svg';
import { ReactComponent as Eye } from '../../../assets/Icon-eye.svg';
import '@themesberg/flowbite';
import {
  Customer, selectStateRegistryService, actions as customersActions, selectError,
} from '..';
import { selectSelectedOrder } from '../../Orders';

export interface RegistryFormProps {
    customer: Customer | undefined
    onSubmit: (values: Customer) => void
}

const schema = yup.object().shape({
  firstName: yup.string().required('Nome richiesto'),
  lastName: yup.string().required('Cognome richiesto'),
  birthCity: yup.string().required('Città richiesta'),
  address: yup.string().required('Indirizzo richiesto'),
  city: yup.string().required('Località richiesta'),
  tel: yup.string().required('Numero di telefono richiesto'),
  email: yup.string().email('Formato email non valido').required('Email richiesta'),
  fiscalCode: yup.string().min(16, 'Deve contenere 16 caratteri').max(16, 'Deve contenere 16 caratteri').required('Codice fiscale richiesto'),
  postalCode: yup.string().length(5, 'Inserire 5 numeri').required('Codice postale richiesto'),
  province: yup.string().length(2, 'Inserire 2 caratteri').required('Provincia richiesta'),
  birthDate: yup.date().min('1900', 'Sei uno zombie?').max('2003', 'I bimbi non sono ammessi').required('Data di nascita richiesta'),
});

const Registry: FunctionComponent<RegistryFormProps> = ({
  customer,
  onSubmit = () => {},
}) => {
  const {
    register, control, handleSubmit, setValue, formState: { errors },
  } = useForm<Customer>({
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const selectedOrder = useSelector(selectSelectedOrder);
  const serviceStateRegistry = useSelector(selectStateRegistryService);
  const selectedError = useSelector(selectError);
  const onSubmitHandler = useCallback((values: Customer) => {
    onSubmit(values);
  }, [onSubmit]);

  useEffect(() => {
    if (serviceStateRegistry === 'rejected') {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [serviceStateRegistry]);

  const onCloseModal = () => {
    if (selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate') {
      dispatch(customersActions.setError());
    }
    setShowModal(false);
  };

  return (
    <div>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className={((customer || (selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate' && !customer))) ? 'p-4 cursor-pointer w-full hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center' : 'p-4 cursor-pointer w-full hover:bg-secondary transition duration-500 ease-in-out flex items-center justify-center btn-disabled'}
      >
        <h1 className="mr-4">{customer?.id || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate') ? 'Visualizza anagrafica' : 'Inserisci anagrafica'}</h1>
        {customer?.id || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate')
          ? <Eye />
          : <Plus />}
      </button>
      {showModal ? (
        <div>
          <div className="justify-center items-center flex overflow-y-auto fixed inset-0 z-50 w-full mx-auto my-0">
            <div className="relative my-6 h-screen lg:w-auto w-full">
              {/* content */}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">SCHEDA CLIENTE</h3>
                  <button
                    type="button"
                    className=""
                    onClick={onCloseModal}
                  >
                    <Close className="text-right" />
                  </button>
                </div>
                {/* body */}
                {(((selectedOrder?.status === 'acquisition' || selectedOrder?.status === 'estimate') && !customer) || (serviceStateRegistry === 'rejected' && customer && selectedError))
                && (
                <form>
                  <div className="relative">
                    <div className="flex flex-col">
                      <div className="flex flex-col sm:flex-row items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold w-full">
                          Cognome e Nome:
                        </h1>
                        <div className="relative mr-0 sm:mr-8 w-full my-8">
                          <input
                            type="text"
                            placeholder="Cognome"
                            className={errors.lastName ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                            {...register('lastName', {
                              required: true,
                            })}
                          />
                          {errors.lastName && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.lastName.message}
                          </span>
                          )}
                        </div>
                        <div className="relative w-full my-4">
                          <input
                            type="text"
                            placeholder="Nome"
                            className={errors.firstName ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                            {...register('firstName', {
                              required: true,
                            })}
                          />
                          {errors.firstName && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.firstName.message}
                          </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-between">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8">
                          Nato/a:
                        </h1>
                        <div className="flex items-center w-full sm:w-auto my-8 sm:my-4">
                          <h1 className="text-xl font-semibold mr-8">
                            a
                          </h1>
                          <div className="relative w-full mr-0 sm:mr-8">
                            <input
                              type="text"
                              placeholder="Città"
                              className={errors.birthCity ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                              {...register('birthCity', {
                                required: true,
                              })}
                            />
                            {errors.birthCity && (
                            <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                              {errors.birthCity.message}
                            </span>
                            )}
                          </div>
                        </div>
                        <div className="flex items-center w-full sm:w-auto my-4">
                          <h1 className="text-xl font-semibold mr-8">
                            il
                          </h1>
                          <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <svg
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <Controller
                              name="birthDate"
                              control={control}
                              render={({ field, fieldState: { invalid, error } }) => (
                                <div>
                                  <DatePicker
                                    className={errors.birthDate ? 'border-red-500 border-2 h-12 bg-gray-50 text-primary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' : 'h-12 bg-gray-50 border border-primary text-primary sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'}
                                    value={field.value && new Date(field.value) || null}
                                    onChange={(value: Date) => setValue('birthDate', value && value.toISOString())}
                                  />
                                </div>
                              )}
                            />
                            {errors.birthDate && (
                            <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                              {errors.birthDate.message}
                            </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold mr-8">
                          Residenza:
                        </h1>
                        <div className="flex items-center my-8 sm:my-4 w-full">
                          <h1 className="text-xl font-semibold mr-8">
                            Via
                          </h1>
                          <div className="relative w-full">
                            <input
                              type="text"
                              placeholder="Indirizzo"
                              className={errors.address ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                              {...register('address', {
                                required: true,
                              })}
                            />
                            {errors.address && (
                            <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                              {errors.address.message}
                            </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-end bg-gray-200">
                        <div className="relative mr-0 sm:mr-8 mb-8 sm:mb-8">
                          <input
                            type="text"
                            placeholder="Località"
                            className={errors.city ? 'input input-primary input-bordered border-red-500 border-2' : 'input input-primary input-bordered'}
                            {...register('city', {
                              required: true,
                            })}
                          />
                          {errors.city && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.city.message}
                          </span>
                          )}
                        </div>
                        <div className="flex mb-12 mt-4">
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="CAP"
                              className={errors.postalCode ? 'input input-primary input-bordered border-red-500 border-2 w-24 mr-8' : 'input input-primary input-bordered w-24 mr-8'}
                              {...register('postalCode', {
                                required: true,
                              })}
                            />
                            {errors.postalCode && (
                            <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                              {errors.postalCode.message}
                            </span>
                            )}
                          </div>
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Prov."
                              className={errors.province ? 'input input-primary input-bordered w-24 border-red-500 border-2' : 'input input-primary input-bordered w-24'}
                              {...register('province', {
                                required: true,
                              })}
                            />
                            {errors.province && (
                            <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                              {errors.province.message}
                            </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row  items-start sm:items-center p-6 justify-between">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8">
                          Contatti:
                        </h1>
                        <div className="relative w-full mr-0 sm:mr-8 my-8 sm:my-4">
                          <input
                            type="text"
                            placeholder="Numero di telefono"
                            className={errors.tel ? 'input input-primary input-bordered w-full border-red-500 border-2' : 'input input-primary input-bordered w-full'}
                            {...register('tel', {
                              required: true,
                            })}
                          />
                          {errors.tel && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.tel.message}
                          </span>
                          )}
                        </div>
                        <div className="relative w-full my-4">
                          <input
                            type="email"
                            placeholder="email"
                            className={errors.email ? 'input input-primary input-bordered border-red-500 border-2 w-full' : 'input input-primary input-bordered w-full'}
                            {...register('email', {
                              required: true,
                            })}
                          />
                          {errors.email && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.email.message}
                          </span>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8 mb-4 sm:mb-0 w-2/3">
                          Codice fiscale:
                        </h1>
                        <div className="relative w-full my-4 sm:my-8">
                          <input
                            type="text"
                            placeholder="Codice fiscale"
                            className={errors.fiscalCode ? 'uppercase input input-primary input-bordered w-full border-red-500 border-2' : 'uppercase input input-primary input-bordered w-full'}
                            {...register('fiscalCode', {
                              required: true,
                            })}
                          />
                          {errors.fiscalCode && (
                          <span className="absolute top-10 left-1 mt-4 text-sm text-red-500 font-semibold">
                            {errors.fiscalCode.message}
                          </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* footer */}
                  <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                      onClick={handleSubmit(onSubmitHandler)}
                      className="btn w-full md:w-48 btn-secondary rounded-full my-6 py-3 px-6 font-bold border-0"
                      type="button"
                    >
                      Salva
                    </button>
                  </div>
                </form>
                )}
                {((customer && serviceStateRegistry !== 'rejected') || (selectedOrder?.status !== 'acquisition' && selectedOrder?.status !== 'estimate'))
                && (
                <form>
                  <div className="relative">
                    <div className="flex flex-col">
                      <div className="flex flex-col sm:flex-row items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold w-full">
                          Cognome e Nome:
                        </h1>
                        <label className="relative mr-0 sm:mr-8 w-full my-8 tooltip tooltip-primary" htmlFor="lastName" data-tip="Cognome">
                          <input
                            value={customer?.lastName}
                            id="lastName"
                            type="text"
                            placeholder="Cognome"
                            className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                        <label className="relative w-full my-4 tooltip tooltip-primary" htmlFor="firstName" data-tip="Nome">
                          <input
                            type="text"
                            id="firstName"
                            value={customer?.firstName}
                            placeholder="Nome"
                            className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-between">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8">
                          Nato/a:
                        </h1>
                        <div className="flex items-center w-full sm:w-auto my-8 sm:my-4">
                          <h1 className="text-xl font-semibold mr-8">
                            a
                          </h1>
                          <label className="relative w-full mr-0 sm:mr-8 tooltip tooltip-primary" htmlFor="birthCity" data-tip="Città">
                            <input
                              type="text"
                              id="birthCity"
                              value={customer?.birthCity}
                              placeholder="Città"
                              className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                              disabled
                            />
                          </label>
                        </div>
                        <div className="flex items-center w-full sm:w-auto my-4">
                          <h1 className="text-xl font-semibold mr-8">
                            il
                          </h1>
                          <div className="relative w-full">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <svg
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </div>
                            <label className="tooltip tooltip-primary" data-tip="Data di nascita">
                              <Controller
                                data-tip="data"
                                name="birthDate"
                                control={control}
                                render={({ field, fieldState: { invalid, error } }) => (
                                  <div>
                                    <DatePicker
                                      className="hiddenBtn h-12 bg-info sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-default text-white font-semibold"
                                      value={customer ? new Date(customer.birthDate) : null}
                                      onChange={(value: Date) => setValue('birthDate', value && value.toISOString())}
                                      disabled
                                    />
                                  </div>
                                )}
                              />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold mr-8">
                          Residenza:
                        </h1>
                        <div className="flex items-center my-8 sm:my-4 w-full">
                          <h1 className="text-xl font-semibold mr-8">
                            Via
                          </h1>
                          <label className="relative w-full tooltip tooltip-primary" htmlFor="address" data-tip="Indirizzo">
                            <input
                              value={customer?.address}
                              id="address"
                              type="text"
                              placeholder="Indirizzo"
                              className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                              disabled
                            />
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row sm:items-center p-6 justify-end bg-gray-200">
                        <label className="relative mr-0 sm:mr-8 mb-8 sm:mb-8 tooltip tooltip-primary" htmlFor="city" data-tip="Località">
                          <input
                            type="text"
                            value={customer?.city}
                            id="city"
                            placeholder="Località"
                            className="input input-primary input-bordered cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                        <div className="flex mb-12 mt-4">
                          <label className="relative tooltip tooltip-primary" htmlFor="postalCode" data-tip="CAP">
                            <input
                              type="text"
                              id="postalCode"
                              value={customer?.postalCode}
                              placeholder="CAP"
                              className="input input-primary input-bordered w-24 mr-8 cursor-default text-white font-semibold"
                              disabled
                            />
                          </label>
                          <label className="relative tooltip tooltip-primary" htmlFor="province" data-tip="Provincia">
                            <input
                              type="text"
                              id="province"
                              value={customer?.province}
                              placeholder="Prov."
                              className="input input-primary input-bordered w-24 cursor-default text-white font-semibold"
                              disabled
                            />
                          </label>
                        </div>
                      </div>
                      <div className="flex flex-col sm:flex-row  items-start sm:items-center p-6 justify-between">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8">
                          Contatti:
                        </h1>
                        <label className="relative w-full mr-0 sm:mr-8 my-8 sm:my-4 tooltip tooltip-primary" htmlFor="tel" data-tip="Numero di telefono">
                          <input
                            type="text"
                            id="tel"
                            value={customer?.tel}
                            placeholder="Numero di telefono"
                            className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                        <label className="relative w-full my-4 tooltip tooltip-primary" htmlFor="email" data-tip="Email">
                          <input
                            type="email"
                            id="email"
                            value={customer?.email}
                            placeholder="email"
                            className="input input-primary input-bordered w-full cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                      </div>
                      <div className="flex flex-col sm:flex-row items-start sm:items-center p-6 justify-between bg-gray-200">
                        <h1 className="text-xl font-semibold mr-0 sm:mr-8 mb-4 sm:mb-0 w-2/3">
                          Codice fiscale:
                        </h1>
                        <label className="relative w-full my-4 sm:my-8 tooltip tooltip-primary" htmlFor="fiscalCode" data-tip="Codice fiscale">
                          <input
                            type="text"
                            id="fiscalCode"
                            value={customer?.fiscalCode.toUpperCase()}
                            placeholder="Codice fiscale"
                            className="uppercase input input-primary input-bordered w-full cursor-default text-white font-semibold"
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {/* footer */}
                </form>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black" />
        </div>
      ) : null}
    </div>
  );
};

export default Registry;

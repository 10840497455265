import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'src/store';
import {
  Estimate,
  Order,
  OrderDocument,
  StateChangeToOpened,
  StateChangeToInstalled,
  MissingDocuments,
  selectDocuments,
} from '.';
import { PaginationInput, PaginationOutput } from '../Common';

export const getOrders = createAsyncThunk('orders/getOrders',
  async (pagination: PaginationInput | undefined = { page: 1, perPage: 5 }, { getState }) => {
    const { filters } = (getState() as RootState).orders;

    const response = await axios
      .get<PaginationOutput<Order>>(`${process.env.REACT_APP_API_BASE_URL}/api/v1/orders`, {
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
        params: {
          ...filters,
          ...pagination,
        },
      })
      .then((res) => res);
    return response;
  });

export const getOrder = createAsyncThunk(
  'orders/getOrder',
  async (orderId: number, { getState }) => {
    const response = await axios
      .get<Order>(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${orderId}`,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);
    return response;
  },
);

export const getOrderDocuments = createAsyncThunk(
  'orders/getOrderDocuments',
  async (orderId: number, { getState }) => {
    const response = await axios
      .get<OrderDocument[]>(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${orderId}/documents`,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);
    return response;
  },
);

export const changeStateToInstalled = createAsyncThunk(
  'orders/changeStateToInstalled',
  async (values: StateChangeToInstalled, { getState, dispatch }) => {
    let missingDocuments: Array<MissingDocuments> | null = null;
    if ((getState() as RootState).orders.selected?.status.includes('rejected')) {
      missingDocuments = await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/documents/check`,
          {
            headers: {
              Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
              'Content-Type': 'multipart/form-data',
            },
          },
        ).then((res) => res.data.missing);
    }
    let formDataToSend;
    if (missingDocuments) {
      const formDataMissing = new FormData();
      missingDocuments.map(({ type }) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'pdc':
            values.pdc?.forEach((doc, i) => {
              // eslint-disable-next-line max-len
              if (doc.pdc) {
                if (values.pdc?.length === 1) {
                  formDataMissing.append(`pdc:${doc.id}`, doc.pdc);
                } else {
                  formDataMissing.append(`pdc:${doc.id}-${i + 1}`, doc.pdc);
                }
              }
            });
            break;
          case 'test':
            if (values.test) {
              formDataMissing.append('test', values.test);
            }
            break;
          case 'intervention-sheet':
            if (values.interventionSheet) {
              formDataMissing.append('intervention-sheet', values.interventionSheet);
            }
            break;
          case 'invoice':
            if (values.invoice) {
              formDataMissing.append('invoice', values.invoice);
            }
            break;
        }
        return formDataMissing;
      });
      formDataToSend = formDataMissing;
    } else {
      const formData = new FormData();
      values.pdc?.forEach((doc, i) => {
        if (doc.pdc) {
          if (values.pdc?.length === 1) {
            formData.append(`pdc:${doc.id}`, doc.pdc);
          } else {
            formData.append(`pdc:${doc.id}-${i + 1}`, doc.pdc);
          }
        }
      });
      if (values.test) {
        formData.append('test', values.test);
      }
      if (values.interventionSheet) {
        formData.append('intervention-sheet', values.interventionSheet);
      }
      if (values.invoice) {
        formData.append('invoice', values.invoice);
      }

      formDataToSend = formData;
    }

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/documents`,
        formDataToSend,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((res) => res);

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/installed`, {},
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);

    dispatch(getOrders());
  },
);

export const changeStateToEstimate = createAsyncThunk(
  'orders/changeStateToEstimate',
  async (values: Estimate, { getState, dispatch }) => {
    const response = await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/estimate`,
        values,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);

    dispatch(getOrders());
    return response;
  },
);

export const changeStateToOpened = createAsyncThunk(
  'orders/changeStateToOpened',
  async (values: StateChangeToOpened, { getState, dispatch }) => {
    let missingDocuments: Array<MissingDocuments> | null = null;
    if ((getState() as RootState).orders.selected?.status.includes('rejected')) {
      missingDocuments = await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/documents/check`,
          {
            headers: {
              Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then((res) => res.data.missing);
    }
    let formDataToSend;
    if (missingDocuments) {
      const formDataMissing = new FormData();
      missingDocuments.map(({ type }) => {
        // eslint-disable-next-line default-case
        switch (type) {
          case 'identity-card':
            /* values.identity?.forEach((value, i) => {
              // eslint-disable-next-line max-len
              if (
                value.identityFront
                                && value.identityBack
                                && value.fiscalCodeFront
                                && value.fiscalCodeBack
                                && i === 0
              ) {
                formDataMissing.append('identity-front', value.identityFront);
                formDataMissing.append('identity-rear', value.identityBack);
                formDataMissing.append('fiscal-code-front', value.fiscalCodeFront);
                formDataMissing.append('fiscal-code-rear', value.fiscalCodeBack);
              }
            }); */
            if (values.identityCard) {
              formDataMissing.append('identity-card', values.identityCard);
            }
            break;
          case 'fiscalcode-card':
            if (values.fiscalCodeCard) {
              formDataMissing.append('fiscalcode-card', values.fiscalCodeCard);
            }
            break;
          case 'property-deed':
            if (values.propertyDeed) {
              formDataMissing.append('property-deed', values.propertyDeed);
            }
            break;
          case 'accepted-offer':
            if (values.acceptedOffer) {
              formDataMissing.append('accepted-offer', values.acceptedOffer);
            }
            break;
          case 'cadastral-certificate':
            if (values.cadastral) {
              formDataMissing.append('cadastral-certificate', values.cadastral);
            }

            break;
          case 'building-permit':
            if (values.housing) {
              formDataMissing.append('building-permit', values.housing);
            }
            break;
        }
        return formDataMissing;
      });

      formDataToSend = formDataMissing;
    } else {
      const formData = new FormData();
      // // Update the formData object
      /*  values.identity?.forEach((value, i) => {
        // eslint-disable-next-line max-len
        if (
          value.identityFront
                    && value.identityBack
                    && value.fiscalCodeFront
                    && value.fiscalCodeBack
                    && i === 0
        ) {
          formData.append('identity-front', value.identityFront);
          formData.append('identity-rear', value.identityBack);
          formData.append('fiscal-code-front', value.fiscalCodeFront);
          formData.append('fiscal-code-rear', value.fiscalCodeBack);
        }
      }); */
      values.documents?.forEach((doc, i) => {
        if (doc.photoLabelGenerator && doc.photoOldGenerator) {
          if (values.documents?.length === 1) {
            formData.append(`old-prod:${doc.id}`, doc.photoOldGenerator);
            formData.append(`old-prod-label:${doc.id}`, doc.photoLabelGenerator);
          } else {
            formData.append(`old-prod:${doc.id}-${i + 1}`, doc.photoOldGenerator);
            formData.append(
              `old-prod-label:${doc.id}-${i + 1}`,
              doc.photoLabelGenerator,
            );
          }
        }
      });
      if (values.propertyDeed && values.cadastral && values.housing
        && values.identityCard && values.fiscalCodeCard && values.acceptedOffer) {
        formData.append('identity-card', values.identityCard);
        formData.append('fiscalcode-card', values.fiscalCodeCard);
        formData.append('property-deed', values.propertyDeed);
        formData.append('cadastral-certificate', values.cadastral);
        formData.append('building-permit', values.housing);
        formData.append('accepted-offer', values.acceptedOffer);
      }
      formDataToSend = formData;
    }
    // // Request made to the backend api
    // // Send formData object
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/documents`,
        formDataToSend,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((res) => res);

    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${values.id}/open`,
        { buildingId: values.buildingId },
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);

    dispatch(getOrders());
  },
);

export const printOffer = createAsyncThunk(
  'orders/printOffer',
  async (orderId: number, { getState }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/orders/${orderId}/print`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res);

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    return response;
  },
);

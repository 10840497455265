import { combineReducers } from '@reduxjs/toolkit';
import { reducer as authReducer } from 'src/features/Auth';
import { reducer as commonReducer } from 'src/features/Common';
import { reducer as ordersReducer } from '../features/Orders';
import { reducer as customersReducer } from '../features/Customers';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  orders: ordersReducer,
  customers: customersReducer,
});

export default rootReducer;

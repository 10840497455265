import React, { FunctionComponent } from 'react';
import StoreLogo from '../assets/3bstorelogo.png';

export interface LoaderProps {
    active: boolean
}

const Loader: FunctionComponent<LoaderProps> = ({ active }) => active && (
  <div className="flex justify-center">
    <article className="fixed z-50 top-1/2 animate-bounce p-5 border-2 bg-primary">
      <img src={StoreLogo} alt="3bstore logo" />
    </article>
  </div>
) || null;

export default Loader;

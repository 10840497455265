import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { RootState } from 'src/store';
import {
  Building, Customer, searchBuildingResults, ServerValidationError,
} from '.';

export const createRegistry = createAsyncThunk(
  'orders/createRegistry',
  async (values: Customer, { getState }) => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customers`, values, {
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res).catch((err: AxiosError<ServerValidationError>) => {
        throw new Error(JSON.stringify({
          statusCode:
          err.response?.status,
          statusText: err.response?.statusText,
          ...err.response?.data,
        }));
      });
    return response;
  },
);

export const createBuilding = createAsyncThunk(
  'orders/createBuilding',
  async (values: Building, { getState }) => {
    const response = await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/buildings`, values, {
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res).catch((err: AxiosError<ServerValidationError>) => {
        throw new Error(JSON.stringify({
          statusCode:
          err.response?.status,
          statusText: err.response?.statusText,
          ...err.response?.data,
        }));
      });
    return response;
  },
);

export const searchRegistry = createAsyncThunk(
  'orders/searchRegistry',
  async (input: string, { getState }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/search?text=${input}`, {
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res);
    return response;
  },
);

export const searchBuilding = createAsyncThunk(
  'orders/searchBuilding',
  async (values: searchBuildingResults, { getState }) => {
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/buildings/search?customer_id=${values.customerId}&address=${values.address}`,
        {
          headers: {
            Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
          },
        },
      )
      .then((res) => res);
    return response;
  },
);

export const printRegistry = createAsyncThunk(
  'orders/printRegistry',
  async (customerId: number, { getState }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/customers/${customerId}/print`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res);

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    return response;
  },
);

export const printBuilding = createAsyncThunk(
  'orders/printBuilding',
  async (buildingId: number, { getState }) => {
    const response = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/api/v1/buildings/${buildingId}/print`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `bearer ${(getState() as RootState).auth.token}` || '',
        },
      })
      .then((res) => res);

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    return response;
  },
);

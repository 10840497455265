/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectOrderError } from 'src/features/Orders';
import { isServerValidationError, selectError } from '../../Customers';
import { ReactComponent as Close } from '../../../assets/Icon-close.svg';

export interface ErrorsProps {
  onClose: () => void
}

const ErrorsModal: FunctionComponent<ErrorsProps> = ({ onClose }) => {
  const selectedError = useSelector(selectError);
  const selectedOrderError = useSelector(selectOrderError);
  return (
    <div className="modal modal-open flex justify-center items-center">
      <div className="modal-box">
        <h1 className="text-red-600 font-semibold">ERRORE</h1>
        <div className="modal-action">
          <label
            htmlFor="rejected-modal"
            className="absolute top-4 right-8 cursor-pointer"
            onClick={onClose}
          >
            <Close />
          </label>
          <input type="checkbox" id="rejected-modal" className="modal-toggle" />
          <div className="ml-0 flex flex-col w-full">
            {!selectedOrderError
            && isServerValidationError(selectedError) && selectedError.errors.map((e) => (
              <div className="flex justify-start items-center text-lg font-medium my-2">
                {e.message}
              </div>
            ))}
            {selectedOrderError
              && (
              <div className="flex justify-start items-center text-lg font-medium my-2">
                Errore di comunicazione col server
              </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorsModal;

import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../Auth/services';
import StoreLogo from '../../../assets/3bstorelogo.png';
import { ReactComponent as Logout } from '../../../assets/Icon-logout.svg';

const Header: FunctionComponent = () => {
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <div className="bg-primary h-32 flex items-center fixed w-full top-0 left-0 z-10 justify-between">
      <div className="w-48 mx-2 md:mx-12">
        <img src={StoreLogo} alt="" className="w-full" />
      </div>
      <button
        type="button"
        className="btn bg-white text-primary border-primary border-2 mr-2 md:mr-12 ml-4"
        onClick={onLogout}
      >
        <Logout className="mr-4" />
        Logout
      </button>
    </div>
  );
};

export default Header;
